import React, { useState } from "react";
import { errorTost } from "../../../utils/Helper";
import { changeUserEmail } from "../../../services/admin/AdminServices";
import { errorHandler } from "../../../services/Miscellaneous/ErrorHandlerServices";

const ChangeEmail = () => {
  const [formData, setFormData] = useState({
    oldEmail: "",
    newEmail: "",
  });

  const onChangeHandler = (e) => {
    let { name, value } = e.target;
    setFormData((pre) => ({ ...pre, [name]: value }));
  };

  const { newEmail, oldEmail , newName } = formData;

  const updateEmailHandler = async () => {
    if (!newEmail && !oldEmail) {
      errorTost("Please enter email or refferl code");
      return;
    }
    try {
      const response = await changeUserEmail(formData);
      setFormData((pre) => ({
        ...pre,
        oldEmail: "",
        newEmail: "",
        newName: "",
      }));
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <div className="pt-[10px] w-full h-full flex flex-col justify-center p-4">
      <form class=" mx-auto bg-white  rounded-md shadow-md justify-center w-full md:w-[500px] md:px-6 py-5 flex flex-col justify-center ">
        <div className="self-center mb-5">
          <p className="block mb-1 text-lg font-medium text-gray-500 dark:text-white self-center">
            Change Email
          </p>
        </div>
        <div class="flex mb-5 items-center">
          <label
            for="oldEmail"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white w-[20%]"
          >
            Enter Old Email
          </label>
          <input
            type="email"
            id="email"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:ring-blue-500 focus:border-blue-500 block w-[80%] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="name@flowbite.com"
            value={oldEmail}
            onChange={onChangeHandler}
            required
            name="oldEmail"
          />
        </div>
        <div class="flex mb-5 items-center">
          <label
            for="email"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white w-[20%]"
          >
            Enter new email
          </label>
          <input
            type="email"
            id="newEmail"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:ring-blue-500 focus:border-blue-500 block w-[80%] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter new Email"
            value={newEmail}
            onChange={onChangeHandler}
            required
            name="newEmail"
          />
        </div>
        <div class="flex mb-5 items-center">
          <label
            for="name"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white w-[20%]"
          >
            Enter new name
          </label>
          <input
            type="text"
            id="newName"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:ring-blue-500 focus:border-blue-500 block w-[80%] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter new Name"
            value={newName}
            onChange={onChangeHandler}
            required
            name="newName"
          />
        </div>
        <button
          onClick={() => updateEmailHandler()}
          type="button"
          class="text-white bg-gray-700 w-[200px] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm self-center px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Change Email
        </button>
      </form>
    </div>
  );
};

export default ChangeEmail;
