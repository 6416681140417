import React, { useEffect, useState } from "react";
import { getAllMemberListServices } from "../../../services/user/UserServices";
import { errorHandler } from "../../../services/Miscellaneous/ErrorHandlerServices";
import moment from "moment";
import { getStorageValue } from "../../../services/Miscellaneous/LocalStorageServices";

function TeamList() {
    const [listData,setListData]=useState([])
  const refferralsList = async (userId) => {
    try {
      let response = await getAllMemberListServices(userId);
      setListData(response.result)
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    let storeData = getStorageValue("userBasicDetail");
    console.log(storeData);
    refferralsList(storeData?._id);
  }, []);

  

  return (
    <div className="h-screen scrollable-content">
      {/* Search Table  */}
      <div className="grid lg:grid-cols-1 grid-cols-1 px-4 py-5 m-4 bg-gradient-to-l from-transparent to-blue-900 rounded-2xl border-[0.5px] border-white/10 shadow-xl">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <div className="pb-4 dark:bg-gray-900">
            <label for="table-search" className="sr-only">
              Search
            </label>
            <div className="relative mt-1 ">
              <div className="absolute  inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="text"
                id="table-search"
                className="block pt-2 ps-10 text-sm text-gray-900 border border-userGradiant bg-userGradiant rounded-md"
                placeholder="Search for items"
              />
            </div>
          </div>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-white uppercase bg-userGradiant ">
              <tr>
                <th scope="col" className="p-4">
                  Sl
                </th>
                <th scope="col" className="px-6 py-3">
                  Member Id
                </th>
                <th scope="col" className="px-6 py-3">
                  Referred By
                </th>
                <th scope="col" className="px-6 py-3">
                  Parent Id
                </th>
                <th scope="col" className="px-6 py-3">
                  Email
                </th>
                <th scope="col" className="px-6 py-3">
                  Plan
                </th>

                <th scope="col" className="px-6 py-3">
                  Joinng Date
                </th>
              </tr>
            </thead>
            <tbody>
              {listData?.map((ele, ind) => {
                return (
                  <tr key={ind} className=" border-b  hover:bg-blue-900 ">
                    <td className="w-4 p-4">{ind + 1}</td>
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-white whitespace-nowrap dark:text-white"
                    >
                      {ele?.ownId}
                    </th>
                    <td className="px-6 py-4 text-white">
                      {ele?.referredBy}
                    </td>
                    <td className="px-6 py-4 text-white">
                      {ele?.parentId}
                    </td>
                    <td className="px-6 py-4 text-white">{ele?.email}</td>
                    <td className="px-6 py-4 text-white">
                      {ele?.joiningAmount}
                    </td>
                    <td className="px-6 py-4 text-white">
                      <a
                        href="#"
                        className="font-medium text-white dark:text-blue-500 hover:underline"
                      >
                        {moment(ele?.createdAt).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default TeamList;
