import React, { useEffect, useState } from "react";

const UserMoneyBox = ({ name, money, percent, icon,showSign, boxClass }) => {
  const [textColor, setTextColor] = useState('');
  const [profitLoss, setProfitLoss] = useState('');

  useEffect(()=>{
    if (percent > 0) {
      setTextColor('text-green-500');
      setProfitLoss('+');

    } else if (percent === 0) {
      setTextColor('text-yellow-500');
      setProfitLoss('');
    } else {
      setTextColor('text-red-500');
      setProfitLoss('');
     
    }
  },[])

  return (
    <div className={`flex justify-between items-center px-6 py-7 m-4 rounded-2xl border-[0.5px] border-white/10 shadow-xl ${boxClass || "bg-gradient-to-l from-transparent to-blue-900 text-white"}`}>
      <div>
        <p className="text-gray-300 text-sm">{name}</p>
        <span className="text-white lg:text-2xl font-bold">INR ₹: {money}</span>{" "}
        {/* <span className={`text-green-500 text-sm font-semibold ${textColor}`}>
          {profitLoss}{percent}%
        </span> */}
      </div>
      <div>
        <div className="bg-blue-600 lg:p-3 p-2 lg:rounded-2xl rounded-xl">{icon}</div>
      </div>
    </div>
  )
};

export default UserMoneyBox;
