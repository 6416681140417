import React, { useRef, useState } from "react";
import "../../css/authFile.css";
import bannerVedio from "../../assets/images/bannerVideo.mp4";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginAction } from "../../redux/action/userAction/authAction/AuthAction";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../../assets/logo.png";
import { errorHandler } from "../../services/Miscellaneous/ErrorHandlerServices";
import {
  getOtpForForgetPasswordServices,
  resetPasscodeServices,
  verifyOtpServices,
} from "../../services/auth/AuthServices";
import { errorTost } from "../../utils/Helper";

export const ForgetpasswordView = ({ loginAction, setIsLogin }) => {
  // console.log(loginAction);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    userId: "",
    password: "",
  });

  const [otpModel, setOtpModal] = useState(false);
  const [otpVerifed, setOtpVerifed] = useState(false);

  const { userId, password } = formData;

  const onChangeHandlder = (e) => {
    let { name, value } = e.target;
    setFormData((pre) => ({ ...pre, [name]: value }));
  };

  const onSubmitHandler = async () => {
    if (!userId) {
      errorTost("Please enter email");
      return;
    }
    getOtpHandler();
  };

  const getOtpHandler = async () => {
    let payload = {
      email: userId,
      //   otp: otp,
      //   passcode: password,
    };
    try {
      let response = await getOtpForForgetPasswordServices(payload);
      setOtpModal(true);
    } catch (error) {
      errorHandler(error);
    }
  };
  return (
    <div className="h-screen w-screen  flex ">
      <div className="w-full hidden md:w-2/4 h-screen md:relative md:flex flex-col ">
        <video
          className="h-screen w-full absolute top-0 left-0 object-cover"
          src={bannerVedio}
          controls={false}
          muted
          autoPlay={"autoplay"}
          preLoad="auto"
          loop
        ></video>

        <div className="absolute flex-col bg-transparent top-0  flex justify-center items-center h-screen w-full">
           <span class="text-5xl md:text-[5rem] font-blackOps bg-gradient-to-r from-lightblue via-softpink to-softpink text-transparent bg-clip-text">
            BRFarms
            </span>
          <p className="text-white  font-semibold mt-2 z-50">
            Enhance Your Earnings with Diverse Online Activities
          </p>
        </div>
      </div>
      <div className="w-[100%] p-4 md:p-0 md:w-2/4 h-screen md:bg-userTheme flex justify-center items-center flex-col">
        <video
          className="h-screen w-full  absolute md:hidden top-0 left-0 object-cover"
          src={bannerVedio}
          controls={false}
          muted
          autoPlay={"autoplay"}
          preload="auto"
          loop
        ></video>
        <div className="w-full md:w-[30rem] mx-auto flex flex-col items-center justify-center">
          <img
            src={logo}
            className="h-20 w-20 md:h-40 md:w-40 mr-3 z-50"
            alt="Loading"
          />
          <p className="text-white text text-3xl font-semibold z-50 font-medium  md:text-[2rem] ">
            Nice to see you!
          </p>
          <p className="text-white text font-normal my-2 z-50">
            Enter your email and password to sign in
          </p>
        </div>
        <div className=" bg-gray-400 mt-7 w-full md:w-[30rem] rounded-md bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10 ">
          <form
            // onSubmit={onSubmitHandler}
            class="w-full mx-auto bg-transparent p-5  backdrop-blur-lg	"
          >
            <div class="mb-5">
              <label
                for="email"
                class="block mb-2 text-sm font-medium text-white dark:text-white"
              >
                Your email
              </label>
              <input
                onChange={onChangeHandlder}
                name="userId"
                type="email"
                id="email"
                class=" text-gray-900 text-sm bg-userTheme text-white rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                placeholder="Enter your email"
                required
              />
            </div>
            <div class="mb-5">
              <label
                for="password"
                class="block mb-2 text-sm font-medium text-white dark:text-white"
              >
                Your password
              </label>
              <input
                onChange={onChangeHandlder}
                name="password"
                type="password"
                id="password"
                class=" text-gray-900 text-sm bg-userTheme text-white rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                required
                placeholder="Enter your password"
              />
            </div>

            <button
              onClick={onSubmitHandler}
              type="button"
              class="text-white w-full bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm   px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Submit
            </button>
          </form>
        </div>

        <div className="w-full md:w-[30rem] mt-3">
          <p className="text-white text font-light my-2">
            Don't have an account ?
            <span
              onClick={() => navigate("/signup")}
              className="mx-1 font-semibold cursor-pointer"
            >
              Sign up
            </span>
          </p>
        </div>
      </div>
      {otpModel && <OtpModal userId={userId} />}
    </div>
  );
};

ForgetpasswordView.propTypes = {
  // second: PropTypes.third
  loginAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { loginAction })(ForgetpasswordView);

const OtpModal = ({ numInputs = 6, userId }) => {
    const navigate=useNavigate()
  const [otp, setOtp] = useState(new Array(numInputs).fill(""));
  const inputRefs = useRef([]);

  const handleInputChange = (e, index) => {
    const value = e.target.value;

    if (isNaN(value)) {
      return;
    }

    const newOtp = [...otp];
    newOtp[index] = value;

    setOtp(newOtp);

    // Move to the next input
    if (index < numInputs - 1 && value !== "") {
      inputRefs.current[index + 1].focus();
    }

    let otpValue = newOtp.join("");
  };

  const handleInputKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      // Move to the previous input on Backspace if the current input is empty
      inputRefs.current[index - 1].focus();
    }
  };

  const verifyOtpHandler = async () => {
    try {
      let otpValue = otp.join("");
      let obj = {
        email: userId,
        otp: otpValue,
        passcode:'Test@123'
      };

    //   console.log(obj);
    //   return
      let response = await resetPasscodeServices(obj);
      navigate('/auth')
      
      console.log(response);
      // navigate("/AddtionalInformation");
    } catch (error) {
      errorHandler(error);
    }
  };

  // const verifyOtp = async () => {
  //   let payload = {
  //     userEmail: email,
  //     userOtp: otp,
  //   };
  //   try {
  //     let response = await verifyOtpServices();
  //   } catch (error) {
  //     errorHandler(error);
  //   }
  // };

  return (
    <div class="absolute bg-black/40 h-screen w-screen flex justify-center items-center z-40">
      <div class="relative p-4 w-full max-w-2xl max-h-full">
        <div className="mt-10 justify-evenly  w-full md:w-auto bg-userGradiant  items-center rounded-lg h-80 flex flex-col z-50 ">
          <div className=" border-white items-center justify-center flex z-50">
            {Array.from({ length: numInputs }, (_, index) => (
              <input
                className="bg-lightPurpule h-[15px] w-[50px] mr-3 md:h-[70px] text-center md:w-[80px] py-8 md:py-10 rounded-xl outline-none border-0 focus:outline-0"
                key={index}
                type="text"
                maxLength="1"
                value={otp[index]}
                onChange={(e) => handleInputChange(e, index)}
                onKeyDown={(e) => handleInputKeyDown(e, index)}
                ref={(el) => (inputRefs.current[index] = el)}
              />
            ))}
          </div>

          <button
            onClick={verifyOtpHandler}
            className="bg-blue-700 text-white p-3 px-8 rounded-lg"
          >
            Verify
          </button>
        </div>
      </div>
    </div>
  );
};
