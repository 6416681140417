import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import {
  bussinessReportListServices,
  createUserServices,
  getAllTransaction,
} from "../../../services/admin/AdminServices";
import { toast } from "react-toastify";
import { adminActions } from "../../../redux/action/adminAction/AdminActions/AdminActions";
import { errorHandler } from "../../../services/Miscellaneous/ErrorHandlerServices";

const InvestmentReport = ({ adminActions, adminActionsState }) => {
  const [bussinessReport, setBussinessReport] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const bussinessReportFunction = async () => {
    try {
      let response = await bussinessReportListServices();
      console.log(response);
      setBussinessReport(response.result);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    bussinessReportFunction();
  }, []);

  return (
    <div>
      <div className="flex justify-end "></div>
      <div className=" border-black my-4">
        <ul class="flex flex-wrap text-sm font-medium text-center text-gray-500  mb-5  ">
          <li className="me-2 h-12">
            <p
              onClick={() => setActiveTab(0)}
              class={`${
                activeTab == 0
                  ? "bg-black text-white shadow-xl  rounded-md  "
                  : " bg-white text-black "
              } flex justify-center items-center h-full px-10 cursor-pointer`}
            >
              By Buyer Details
            </p>
          </li>
          <li class="me-2 h-12">
            <p
              onClick={() => setActiveTab(1)}
              class={`${
                activeTab == 1
                  ? "bg-black text-white shadow-xl  rounded-md  "
                  : "bg-white text-black"
              } flex justify-center items-center h-full px-10 cursor-pointer`}
            >
              BRFarms Transaction
            </p>
          </li>
        </ul>
      </div>

      <div class="relative overflow-x-auto h-[75vh] bg-white">
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" class="px-6 py-3">
                Sr No
              </th>
              <th scope="col" class="px-6 py-3">
                Transfer To
              </th>
              <th scope="col" class="px-6 py-3">
               BRFarms
              </th>
              <th scope="col" class="px-6 py-3">
                Date
              </th>
              <th scope="col" class="px-6 py-3">
                Transaction Hash
              </th>
              {/* <th scope="col" class="px-6 py-3">
                Narration
              </th> */}
            </tr>
          </thead>
          <tbody>
            {bussinessReport?.map((ele, i) => {
              return (
                <tr
                  key={i}
                  class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {i + 1}
                  </th>
                  <td class="px-6 py-4">{ele?.memberId}</td>
                  <td class="px-6 py-4">{ele?.email}</td>
                  <td class="px-6 py-4">{ele?.type}</td>
                  {/* <td class="px-6 py-4">{ele?.amount}</td>
                  <td class="px-6 py-4">{ele?.narration}</td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

InvestmentReport.propTypes = {
  //   second: PropTypes.third,
  adminActions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  adminActionsState: state.adminActionsState,
});

export default connect(mapStateToProps, { adminActions })(InvestmentReport);

// export default connect(mapStateToProps, mapDispatchToProps)(BussinesReport)
