import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import "../../css/authFile.css";
import bannerVedio from "../../assets/images/bannerVideo.mp4";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { signUpAction } from "../../redux/action/userAction/authAction/AuthAction";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import { errorTost, successTost } from "../../utils/Helper";
import {
  getOtpServices,
  userSignUpServices,
  verifyOtpServices,
} from "../../services/auth/AuthServices";
import { errorHandler } from "../../services/Miscellaneous/ErrorHandlerServices";
import { setStorageValue } from "../../services/Miscellaneous/LocalStorageServices";
import { motion, AnimatePresence } from "framer-motion";
import { BASE_URL } from "../../utils/Urls";

export const SignUpView = ({ signUpAction }) => {
  // console.log(loginAction);
  const navigate = useNavigate();
  const location = useLocation(); // Access the current URL
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    referredBy: "",
    joiningAmount: "",
    transactionId: "",
    password: "",
  });

  const [setOtp] = useState("");
  const [selectedUnit, setSelectedUnit] = useState(0); // Initialize unit state
  const [verifiedUserData, setVerifiedUserData] = useState(null); // State for verified data

  const maskEmail = (email) => {
    const [name,domain] = email.split("@");
    const maskedName =
      name.slice(0, 4) + "*".repeat(Math.max(0, 4));
    return `${maskedName}`;
  };
  const [showOtp, setShowOtp] = useState(false);
  const [verifiedUser, setVerifiedUser] = useState(false);
  const [verifiedUser1, setVerifiedUser1] = useState(false);

  // Extract referredBy from URL query parameters
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const referredBy = params.get("referredBy");
    if (referredBy) {
      setFormData((prevState) => ({
        ...prevState,
        referredBy, // Update the referredBy field in formData
      }));
    }
  }, [location.search]);

  const { name, email, mobile, referredBy, joiningAmount, password } = formData;

  const onChangeHandlder = (e) => {
    let { name, value } = e.target;

    // Check if the field is joiningAmount
    if (name === "joiningAmount") {
      const calculatedValue = Number(value) * 15000; // Calculate value based on unit
      setSelectedUnit(Number(value)); // Update selected unit state
      value = calculatedValue; // Update value with calculated amount
    }

    setFormData((pre) => ({ ...pre, [name]: value })); // Update form data
  };

  function onChangeConfirmPasswordValidate(event) {
    const confirmPassword = event.target.value;
    const originalPassword = document.getElementById("password").value;

    if (originalPassword !== confirmPassword) {
      // Confirm password doesn't match
      errorTost("Confirm password does not match");
      return;
    } else {
      // Confirm password matches
      successTost("Confirm password matches");
    }
  }

  const getOtp = async () => {
    if (!email) {
      errorTost("Please enter email");
      return;
    }
    let payload = {
      email: email,
    };
    try {
      let response = await getOtpServices(payload);
      setShowOtp(true);
    } catch (error) {
      errorHandler(error);
      // console.log(error);
    }
  };
  const handleKeyPress = (event) => {
    // Allow only numeric characters
    const charCode = event.charCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };

  const onSubmitHandler = async () => {
    console.log(formData);
    if (!name) {
      errorTost("Enter name");
      return;
    }
    if (!mobile) {
      errorTost("Enter mobile");
      return;
    }
    if (!email) {
      errorTost("Enter email");
    }

    if (!password) {
      errorTost("Enter password");
      return;
    }

    delete formData.confirmPassword;

    try {
      let response = await userSignUpServices({
        ...formData,
      });

      navigate("/auth");

      // setStorageValue('adminToken',re)
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="h-screen w-screen  flex bg-userTheme overflow-hidden  ">
      <div className="w-full hidden md:w-2/4 h-screen md:relative  md:flex flex-col ">
        <video
          className="h-screen w-full absolute top-0 left-0 object-cover"
          src={bannerVedio}
          controls={false}
          muted
          autoPlay={"autoplay"}
          preLoad="auto"
          loop
        ></video>

        <div className="absolute flex-col bg-transparent top-0 bg-white bg-opacity-60 flex justify-center items-center h-screen w-full">
          <img src={logo} className=" mr-3 " alt="Loading" />
          <p className="text-black  font-semibold mt-2 z-50">
            Luxury Living with BRfarms
          </p>
        </div>
      </div>

      <div className="w-[100%] p-4 md:p-0 md:w-2/4 h-screen md:bg-userTheme flex justify-center items-center flex-col">
        <video
          className="h-screen w-full absolute md:hidden top-0 left-0 object-cover"
          src={bannerVedio}
          controls={false}
          muted
          autoPlay={"autoplay"}
          preLoad="auto"
          loop
        ></video>

        <div className="w-full md:w-[30rem] mx-auto flex flex-col items-center justify-center">
          <p className="text-white text text-3xl font-semibold z-50 font-medium  md:text-[2rem] ">
            Nice to see you!
          </p>
          <p className="text-white text font-normal my-2">Enter detail to</p>
        </div>
        <div className=" bg-gray-400 mt-7 w-full md:w-[30rem] rounded-md bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10 rounded-full shadow-5xl">
          <form
            // onSubmit={onSubmitHandler}
            class="w-full mx-auto bg-transparent p-5  backdrop-blur-lg	"
          >
            <div class="mb-5">
              <input
                onChange={onChangeHandlder}
                name="name"
                class=" text-gray-900 text-sm bg-userTheme text-white rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                placeholder="Enter your name"
                required
              />
            </div>

            <div class="mb-5  relative">
              <input
                disabled={verifiedUser}
                onChange={onChangeHandlder}
                name="email"
                type="email"
                id="email"
                class=" text-gray-900 text-sm bg-userTheme text-white rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                placeholder="Enter your email"
                required
              />

              <button
                type="button"
                data-modal-target="default-modal"
                data-modal-toggle="default-modal"
                onClick={() => getOtp()}
                class={`absolute inset-y-0 right-2 px-4 py-1 rounded-lg flex items-center justify-center focus:outline-none ${
                  verifiedUser
                    ? "bg-green-500 text-white hover:bg-green-600"
                    : "bg-blue-500 text-white hover:bg-blue-600"
                }`}
              >
                {verifiedUser ? "Verified" : "Verify"}
              </button>
            </div>

            {showOtp && (
              <div class="mb-5  relative">
                <input
                  onChange={(val) => setOtp(val)}
                  name="otp"
                  type="number"
                  id="otp"
                  class=" text-gray-900 text-sm bg-userTheme text-white rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  placeholder="Enter your Otp"
                  required
                />
              </div>
            )}

            <div class="mb-5 relative">
              <input
                onBlur={() => {
                  if (formData.referredBy.length < 7) {
                    errorTost("Incorrect Referral ID");
                    return;
                  }
                }}
                disabled={!verifiedUser}
                value={formData.referredBy} // Automatically populated from URL if available
                onChange={onChangeHandlder}
                name="referredBy"
                type="text"
                id="referredBy"
                class="text-gray-900 text-sm bg-userTheme text-white rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter referral ID"
                required
              />
              <button
                type="button"
                onClick={async () => {
                  try {
                    const response = await fetch(`${BASE_URL}auth/verifyid`, {
                      method: "POST",
                      headers: { "Content-Type": "application/json" },
                      body: JSON.stringify({ ownId: formData.referredBy }),
                    });
                    const data = await response.json();

                    if (data.status) {
                      successTost(`Referral ID verified successfully`);
                      setFormData((prev) => ({
                        ...prev,
                        referralVerified: true,
                      }));
                      setVerifiedUser1(true);
                      setVerifiedUserData({
                        email: maskEmail(data.user.email),
                        name: data.user.name,
                      });
                    } else {
                      errorTost(
                        data.message ||
                          "Referral ID not verified or does not exist"
                      );
                      setFormData((prev) => ({
                        ...prev,
                        referralVerified: false,
                      }));
                    }
                  } catch (error) {
                    errorTost("Error verifying Referral ID");
                  }
                }}
                class={`absolute inset-y-0 right-2 px-4 py-1 rounded-lg flex items-center justify-center focus:outline-none ${
                  verifiedUser1
                    ? "bg-green-500 text-white hover:bg-green-600"
                    : "bg-blue-500 text-white hover:bg-blue-600"
                }`}
              >
                {verifiedUser1 ? "Verified" : "Verify"}
              </button>
              {formData.referralVerified && (
                <div class="mt-3">
                  <p class="text-sm text-white">
                    <strong>Email:</strong> {verifiedUserData.email}
                    <strong>Name:</strong> {verifiedUserData.name}
                  </p>
                </div>
              )}
            </div>

            <div class="mb-5">
              <input
                disabled={!verifiedUser}
                onChange={onChangeHandlder}
                // onBlur={onChangePasswordValidation}
                name="password"
                type="password"
                id="password"
                class=" text-gray-900 text-sm bg-userTheme text-white rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                required
                placeholder="Enter password"
              />
            </div>
            <div class="mb-5">
              <input
                disabled={!verifiedUser}
                onChange={onChangeHandlder}
                onBlur={onChangeConfirmPasswordValidate}
                name="confirmPassword"
                type="password"
                id="confirmPassword"
                class=" text-gray-900 text-sm bg-userTheme text-white rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                required
                placeholder="Enter confrim password"
              />
            </div>

            <div className="mb-5">
              <input
                disabled={!verifiedUser}
                onChange={onChangeHandlder}
                onKeyPress={handleKeyPress} // Attach the handler function
                name="mobile"
                type="text"
                id="phone"
                maxLength="10" // React uses maxLength instead of max for input length
                className="text-gray-900 text-sm bg-userTheme text-white rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                required
                placeholder="Enter mobile number"
              />
            </div>

            {/* <label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select an option</label> */}
            <div className="mb-5">
              <select
                disabled={!verifiedUser}
                name="joiningAmount"
                id="joiningAmount"
                onChange={onChangeHandlder} // Update state with calculated amount
                className="text-gray-900 text-sm bg-userTheme text-white rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option value="" selected>
                  Select Unit
                </option>
                {[...Array(15)].map((_, index) => (
                  <option key={index + 1} value={index + 1}>
                    Unit {index + 1}
                  </option>
                ))}
              </select>
            </div>

            {selectedUnit > 0 && (
              <div className="mt-3 text-white">
                <p>
                  You need to pay <b>₹{selectedUnit * 15000}</b> or{" "}
                  <b>$ {(selectedUnit * 15000) / 90}</b>to activate the account
                  and can earn .
                </p>
              </div>
            )}

            <button
              disabled={!formData.referralVerified}
              onClick={onSubmitHandler}
              type="button"
              class="text-white mt-5 w-full bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm   px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Submit
            </button>
          </form>
        </div>

        <div className="w-full md:w-[30rem] mt-3">
          <p className="text-white text font-light my-2">
            Don't have an account ?
            <span className="mx-1 font-semibold cursor-pointer">
              <span onClick={() => navigate("/auth")}>Login</span>
            </span>
          </p>
        </div>
      </div>
      {showOtp && (
        <OtpModal
          email={email}
          setVerifiedUser={setVerifiedUser}
          setShowOtp={setShowOtp}
        />
      )}

      <div className=""></div>
    </div>
  );
};

SignUpView.propTypes = {
  // second: PropTypes.third
  loginAction: PropTypes.func.isRequired,
  signUpAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { signUpAction })(SignUpView);

export const OtpModal = ({
  numInputs = 6,
  email,
  setVerifiedUser,
  setShowOtp,
}) => {
  const [otp, setOtp] = useState(new Array(numInputs).fill(""));
  const inputRefs = useRef([]);

  const handleInputChange = (e, index) => {
    const value = e.target.value;

    if (isNaN(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;

    setOtp(newOtp);

    // Move to the next input
    if (index < numInputs - 1 && value !== "") {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleInputKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      inputRefs.current[index - 1].focus();
    }
  };

  const verifyOtpHandler = async () => {
    try {
      const otpValue = otp.join("");
      const obj = { userEmail: email, userOtp: otpValue };
      const response = await verifyOtpServices(obj);
      setShowOtp(false);
      setVerifiedUser(true);
      successTost("Email Verified");
      console.log(response);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        className="absolute bg-black/60 h-screen w-screen flex justify-center items-center z-50"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <motion.div
          className="relative p-6 w-full max-w-lg bg-white rounded-xl shadow-xl"
          initial={{ scale: 0.9 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0.9 }}
        >
          <h2 className="text-xl font-semibold text-center text-gray-800">
            Enter OTP
          </h2>
          <p className="text-center text-gray-500 mt-2">
            A verification code was sent to your email: <br />
            <span className="font-semibold">{email}</span>
          </p>

          <div className="flex justify-center mt-6 space-x-3">
            {Array.from({ length: numInputs }, (_, index) => (
              <motion.input
                key={index}
                className="h-14 w-14 md:h-16 md:w-16 bg-gray-100 text-center rounded-md border-2 border-gray-300 focus:border-blue-500 outline-none text-lg font-medium"
                type="text"
                maxLength="1"
                value={otp[index]}
                onChange={(e) => handleInputChange(e, index)}
                onKeyDown={(e) => handleInputKeyDown(e, index)}
                ref={(el) => (inputRefs.current[index] = el)}
                whileFocus={{ scale: 1.1 }}
              />
            ))}
          </div>

          <div className="mt-8 flex justify-center">
            <motion.button
              onClick={verifyOtpHandler}
              className="bg-blue-600 text-white px-6 py-2 rounded-lg shadow-md hover:bg-blue-700 focus:ring-4 focus:ring-blue-300"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Verify
            </motion.button>
          </div>

          <button
            className="absolute top-3 right-3 text-gray-400 hover:text-gray-600"
            onClick={() => setShowOtp(false)}
          >
            
          </button>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};
