import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import {activationRequestUpdateService, activateUserServices} from "../../../services/admin/AdminServices"
import { adminActivationRequestAction } from "../../../redux/action/adminAction/AdminActions/AdminActions";
import { BASE_FILE_PATH } from "../../../utils/Urls";
import { errorTost, successTost } from "../../../utils/Helper";


const RequestActivationUser = ({
  adminActivationRequestAction,
  adminActivationRequestActionState,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 20;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalViewOpen, setIsModalViewOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [zoom, setZoom] = useState(1);
  const [status, setStatus] = useState("")

  const [selectedUser, setSelectedUser] = useState(null);
 
  const [remarks, setRemarks] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);


  const handleViewImage = (imagePath) => {
    // Extract only the path after "/files/"
    const fileRelativePath = imagePath.split("/files/")[1];
  
    if (!fileRelativePath) {
      console.error("Invalid file path:", imagePath);
      return;
    }
   
  
    setCurrentImage(`${BASE_FILE_PATH}/files/${fileRelativePath}`);
    setZoom(1); // Reset zoom
    setIsModalViewOpen(true);
  };
  
  const handleZoomIn = () => {
    setZoom((prev) => prev + 0.1);
  };

  const handleZoomOut = () => {
    setZoom((prev) => Math.max(0.1, prev - 0.1));
  };
  // Trigger the action to fetch activation requests
  useEffect(() => {
    adminActivationRequestAction(); // Fetch data when the component mounts
  }, [adminActivationRequestAction]);

  // Calculate pagination details
  const totalRows = adminActivationRequestActionState?.length || 0; // Works only if it's an array

  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const currentRows = adminActivationRequestActionState?.slice(
    startIndex,
    startIndex + rowsPerPage
  );

  // Pagination controls
  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrev = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const statusMappings = {
    pending: { className: "pending", text: "Pending" },
    approved: { className: "approved", text: "Approved" },
    rejected: { className: "rejected", text: "Rejected" },
  };
  
  const handleActivateUser = async () => {
    if (!status || !remarks) {
      setErrors({
        status: !status ? "Please select a status" : "",
        remarks: !remarks ? "Please enter remarks" : "",
      });
      return;
    }

    setIsSubmitting(true);

    const payload = {
      amount: selectedUser.amount,
      email: selectedUser.email,
      transactionId: selectedUser.transactionId || "NIL",
    };

    try {
      const activateResponse = await activateUserServices(payload);

      if (activateResponse?.status) {
        // Update the activation request after user activation
        const updates = {
          status,
          remarks,
          transactionDate: Date.now(),
        };

        await activationRequestUpdateService(selectedUser._id, updates);

        errorTost.success("User activated and request updated successfully!");
        setIsModalOpen(false);
        adminActivationRequestAction(); // Reload table
      } else {
        errorTost.error(activateResponse?.message || "Activation failed");
      }
    } catch (error) {
      errorTost.error(error?.message || "An error occurred during activation");
    } finally {
      setIsSubmitting(false);
    }
  };

  const openModal = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedUser(null);
    setStatus("");
    setRemarks("");
    setErrors({});
    setIsModalOpen(false);
    setIsModalViewOpen(false);
  };


  return (
    <div>
      <div className="border-2 border-black my-4"></div>

      {/* Activation Request Table */}
      <div className="relative overflow-x-auto max-h-[550px] overflow-y-auto border rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th className="px-6 py-3">Sr.no.</th>
              <th className="px-6 py-3 text-center">Transaction Date</th>
              <th className="px-6 py-3 text-center">Name</th>
              <th className="px-6 py-3 text-center">Email</th>
              <th className="px-6 py-3 text-center">Mobile Number</th>
              <th className="px-6 py-3 text-center">Client ID</th>
              <th className="px-6 py-3 text-center">Referred By</th>
              <th className="px-6 py-3 text-center">Amount</th>
              <th scope="col" className="px-6 py-3 text-center">
                View Image
              </th>
              <th className="px-6 py-3 text-center">Status</th>
              <th className="px-6 py-3 text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {currentRows.map((ele, i) => (
              <tr
                key={ele._id}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
              >
                <td className="px-6 py-4 font-medium text-gray-900 dark:text-white">
                  {startIndex + i + 1}
                </td>
                <td className="px-6 py-4 text-center">
                  {new Date(ele.transactionDate).toLocaleDateString()}
                </td>
                <td className="px-6 py-4 text-center">{ele.name || "N/A"}</td>
                <td className="px-6 py-4 text-center">{ele.email}</td>
                <td className="px-6 py-4 text-center">
                  {ele.mobileNumber || "N/A"}
                </td>
                <td className="px-6 py-4 text-center">{ele.ownId || "N/A"}</td>
                <td className="px-6 py-4 text-center">
                  {ele.referredBy || "N/A"}
                </td>
                <td className="px-6 py-4 text-center">
                  {ele.amount.toLocaleString()}
                </td>
                <td className="px-6 py-4 text-center">
                  <span
                    className={`badge ${
                      statusMappings[ele.status]?.className || "default"
                    }`}
                  >
                    {statusMappings[ele.status]?.text || "Unknown"}
                  </span>
                </td>

                <td className="px-6 py-4 text-center">
                <button onClick={() => handleViewImage(ele.imageURL)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 text-blue-500 hover:text-blue-700"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.5 12c0 1.933-1.567 3.5-3.5 3.5S8.5 13.933 8.5 12s1.567-3.5 3.5-3.5S15.5 10.067 15.5 12z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.242 12.242a.998.998 0 011.414 0C4.64 13.227 7.54 16 12 16s7.36-2.773 8.343-3.758a.998.998 0 111.414 1.414C19.36 15.227 16.46 18 12 18s-7.36-2.773-8.343-3.758a.998.998 0 01-1.414-1.414z"
                      />
                    </svg>
                  </button>
                </td>
            
      {/* Activate Button */}
      <td className="px-6 py-4">
                  <button
                    className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700"
                    onClick={() => openModal(ele)}
                  >
                    Activate
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-between items-center mt-4">
        <button
          onClick={handlePrev}
          disabled={currentPage === 1}
          className={`px-4 py-2 text-sm font-medium ${
            currentPage === 1
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-blue-600 text-white hover:bg-blue-700"
          } rounded-lg`}
        >
          Previous
        </button>
        <span className="text-sm font-medium">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={handleNext}
          disabled={currentPage === totalPages}
          className={`px-4 py-2 text-sm font-medium ${
            currentPage === totalPages
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-blue-600 text-white hover:bg-blue-700"
          } rounded-lg`}
        >
          Next
        </button>
      </div>
    
    
     {isModalViewOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="relative bg-white p-6 rounded-lg shadow-lg max-w-3xl w-full">
            <h2 className="text-lg font-semibold mb-4">View Image</h2>
            <div className="flex justify-center items-center overflow-hidden">
              <img
                src={`${currentImage}`}
                alt="Uploaded file"
                className="max-w-full max-h-[500px]"
                style={{ transform: `scale(${zoom})` }}
              />
            </div>
            <div className="flex justify-between mt-4">
              <button
                onClick={handleZoomOut}
                className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400"
              >
                Zoom Out
              </button>
              <button
                onClick={handleZoomIn}
                className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400"
              >
                Zoom In
              </button>
              <button
                 onClick={closeModal}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal for Activation Request */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg">
            <h2 className="text-xl font-bold mb-4">Activate User</h2>
            <div className="mb-4">
              <label className="block text-sm font-medium">Status</label>
              <select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                className={`w-full p-2 border rounded ${
                  errors.status ? "border-red-500" : "border-gray-300"
                }`}
              >
                <option value="">Select Status</option>
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              {errors.status && <p className="text-red-500 text-sm">{errors.status}</p>}
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium">Remarks</label>
              <textarea
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
                className={`w-full p-2 border rounded ${
                  errors.remarks ? "border-red-500" : "border-gray-300"
                }`}
              />
              {errors.remarks && <p className="text-red-500 text-sm">{errors.remarks}</p>}
            </div>
            <div className="mb-4">
              <table className="w-full text-sm">
                <tbody>
                  <tr>
                    <td className="font-medium">Client ID:</td>
                    <td>{selectedUser.clientId}</td>
                  </tr>
                  <tr>
                    <td className="font-medium">Name:</td>
                    <td>{selectedUser.name}</td>
                  </tr>
                  <tr>
                    <td className="font-medium">Email:</td>
                    <td>{selectedUser.email}</td>
                  </tr>
                  <tr>
                    <td className="font-medium">Mobile:</td>
                    <td>{selectedUser.mobile}</td>
                  </tr>
                  <tr>
                    <td className="font-medium">Referred By:</td>
                    <td>{selectedUser.referredBy}</td>
                  </tr>
                  <tr>
                    <td className="font-medium">Amount:</td>
                    <td>{selectedUser.amount}</td>
                  </tr>
                  <tr>
                    <td className="font-medium">Transaction Date:</td>
                    <td>{new Date(selectedUser.transactionDate).toLocaleDateString()}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="flex justify-end">
              <button
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                className={`bg-green-600 text-white px-4 py-2 rounded ml-4 ${
                  isSubmitting ? "cursor-not-allowed opacity-50" : "hover:bg-green-700"
                }`}
                onClick={handleActivateUser}
                disabled={isSubmitting}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

RequestActivationUser.propTypes = {
  adminActivationRequestAction: PropTypes.func.isRequired,
  adminActivationRequestActionState: PropTypes.shape({
    activationRequestList: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        name: PropTypes.string,
        email: PropTypes.string.isRequired,
        mobileNumber: PropTypes.string,
        ownId: PropTypes.string,
        referredBy: PropTypes.string,
        amount: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        transactionDate: PropTypes.number.isRequired,
      })
    ),
  }),
};
// **Add the propTypes validation here**
RequestActivationUser.propTypes = {
  adminActivationRequestAction: PropTypes.func.isRequired, // Action function
  adminActivationRequestActionState: PropTypes.array, // Updated to expect an array
};

const mapStateToProps = (state) => ({
  adminActivationRequestActionState:
    state.adminActionsState.activationRequestList,
});

export default connect(mapStateToProps, { adminActivationRequestAction })(
  RequestActivationUser
);
