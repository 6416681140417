import React, { useEffect, useState } from "react";
import {
  bussinesReportByType,
  bussinessDetailsBySpecificService,
} from "../../../services/admin/AdminServices";
import { errorHandler } from "../../../services/Miscellaneous/ErrorHandlerServices";
import {
  getDDMMYYYYByTimestamp,
  convertTimestampToMonthAndYear,
} from "../../../utils/DateAndTimeFunctions";

const BussinesReport = () => {
  const [bussinessReport, setBussinessReport] = useState([]);
  const [specificDateData, setSpecificDateData] = useState([]);
  const [showData, setShowData] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);

  const bussinessReportFunction = async (byType = "date") => {
    setLoading(true);
    try {
      const response = await bussinesReportByType(byType);
      setBussinessReport(response.result || []);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const onActionHandler = async (timestamp, index) => {
    if (showData === index) {
      setShowData(null); // Collapse the row if already open
      return;
    }

    setShowData(index); // Expand the clicked row
    setLoading(true); // Show loading spinner while fetching data
    try {
      const response = await bussinessDetailsBySpecificService(timestamp);
      setSpecificDateData(response.result || []);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    bussinessReportFunction(activeTab === 0 ? "date" : "month");
  }, [activeTab]);

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <h1 className="text-2xl font-bold mb-6 text-gray-800">
        Business Report
      </h1>

      {/* Tabs */}
      <div className="flex mb-4">
        <button
          onClick={() => setActiveTab(0)}
          className={`px-6 py-2 rounded-l-md ${
            activeTab === 0
              ? "bg-blue-600 text-white"
              : "bg-gray-200 text-gray-700"
          }`}
        >
          By Date
        </button>
        <button
          onClick={() => setActiveTab(1)}
          className={`px-6 py-2 rounded-r-md ${
            activeTab === 1
              ? "bg-blue-600 text-white"
              : "bg-gray-200 text-gray-700"
          }`}
        >
          By Month
        </button>
      </div>

      {/* Table */}
      <div className="overflow-auto rounded-lg shadow bg-white">
        {loading ? (
          <div className="flex justify-center items-center h-32">
            <p>Loading...</p>
          </div>
        ) : bussinessReport.length === 0 ? (
          <div className="flex justify-center items-center h-32">
            <p className="text-gray-500">No data available.</p>
          </div>
        ) : (
          <table className="w-full text-sm text-left text-gray-700">
            <thead className="bg-gray-100 text-gray-800 uppercase text-xs">
              <tr>
                <th className="px-6 py-3">Sr No</th>
                <th className="px-6 py-3">Date/Month</th>
                <th className="px-6 py-3">Business Amount</th>
                <th className="px-6 py-3">Action</th>
              </tr>
            </thead>
            <tbody>
              {bussinessReport.map((ele, i) => (
                <React.Fragment key={i}>
                  <tr
                    className={`${
                      showData === i ? "bg-blue-50" : "bg-white"
                    } border-b hover:bg-blue-100`}
                  >
                    <td className="px-6 py-4">{i + 1}</td>
                    <td className="px-6 py-4">
                      {activeTab === 0
                        ? getDDMMYYYYByTimestamp(Number(ele.timeStamp))
                        : convertTimestampToMonthAndYear(Number(ele.timeStamp))}
                    </td>
                    <td className="px-6 py-4">{ele.amount}</td>
                    <td className="px-6 py-4">
                      <button
                        onClick={() =>
                          onActionHandler(Number(ele.timeStamp), i)
                        }
                        className="text-blue-600 hover:underline"
                      >
                        {showData === i ? "Hide Details" : "View Details"}
                      </button>
                    </td>
                  </tr>
                  {showData === i && (
                    <tr>
                      <td colSpan="4" className="p-4 bg-blue-50">
                        <h3 className="text-md font-semibold text-gray-800 mb-2">
                          Detailed Report
                        </h3>
                        {specificDateData.length > 0 ? (
                          <table className="w-full text-sm text-left">
                            <thead className="bg-gray-200 text-gray-700 uppercase text-xs">
                              <tr>
                                <th className="px-6 py-3">SN</th>
                                <th className="px-6 py-3">Email</th>
                                <th className="px-6 py-3">Referral ID</th>
                                <th className="px-6 py-3">Amount</th>
                                <th className="px-6 py-3">Narration</th>
                              </tr>
                            </thead>
                            <tbody>
                              {specificDateData.map((item, index) => (
                                <tr
                                  key={index}
                                  className="bg-white border-b hover:bg-gray-100"
                                >
                                  <td className="px-6 py-4">{index + 1}</td>
                                  <td className="px-6 py-4">{item.email}</td>
                                  <td className="px-6 py-4">
                                    {item.referralCode}
                                  </td>
                                  <td className="px-6 py-4">{item.amount}</td>
                                  <td className="px-6 py-4">{item.narration}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <p className="text-gray-500">
                            No detailed data available.
                          </p>
                        )}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default BussinesReport;