// import { toast } from "react-toastify";

import { toast } from "react-toastify";
import { errorTost } from "../../utils/Helper";

export const errorHandler = (err) => {
  try {
    if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
      console.log(err);
    } else if (err?.response?.data?.errors) {
      console.log(err);
      errorTost(err?.response?.data?.errors?.message)
    } else {
      toast.error(err?.response?.data?.error?.message)
      console.log(err.response.data.error.message);
    }
  } 

  catch (error) {
    errorTost('Something went wrong')
  }
};