import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  getAllWithdrawalRequest,
  updateWithdrawalRequest,
} from "../../../services/admin/AdminServices";
import { uploadScreenshotService } from "../../../services/user/UserServices";
import { errorHandler } from "../../../services/Miscellaneous/ErrorHandlerServices";

const AdminWithdrawal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [requests, setRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null); // For tracking the selected request
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [filterStatus, setFilterStatus] = useState("all");
  const [actionData, setActionData] = useState({
    transactionId: "",
    transactionDate: "",
    adminScreenshot: null,
    remark: "",
  });

  const filteredRequests =
    filterStatus === "all"
      ? requests
      : requests.filter((request) =>
          filterStatus === "pending"
            ? request.status === 1
            : filterStatus === "approved"
            ? request.status === 2
            : request.status === 3
        );

  const totalPages = Math.ceil(filteredRequests.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentRequests = filteredRequests.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const handlePageChange = (direction) => {
    if (direction === "next" && currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    } else if (direction === "back" && currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const fetchRequests = async () => {
    try {
      const response = await getAllWithdrawalRequest();
      setRequests(response?.data || []); // Set response data or empty array
    } catch (error) {
      console.error("Error fetching withdrawal requests:", error);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsLoading(true);
      try {
        const picturePath = await uploadScreenshotService(file);
        setActionData({ ...actionData, adminScreenshot: picturePath.path });
      } catch (error) {
        errorHandler(error);
      }
      setIsLoading(false);
    }
  };

  const handleAction = async (requestId, status) => {
    try {
      // Validate actionData before sending
      if (!actionData.transactionId || !actionData.transactionDate) {
        alert("Please fill all required fields.");
        return;
      }

      const payload = {
        requestId,
        status, // Approve or Reject
        transactionId: actionData.transactionId,
        transactionDate: new Date(actionData.transactionDate).toISOString(),
        adminScreenshot: actionData.adminScreenshot, // File path
        remark: actionData.remark || "", // Optional
      };

      console.log("Payload before sending:", payload); // Debugging payload

      // Call the service
      const response = await updateWithdrawalRequest(payload);
      alert(
        status === 2
          ? "Request approved successfully!"
          : "Request rejected successfully!"
      );

      fetchRequests(); // Refresh requests after the update
      setSelectedRequest(null); // Close the modal
    } catch (error) {
      console.error(
        "Error performing action:",
        error.response?.data || error.message
      );
      alert(error.response?.data?.message || "Error performing action.");
    }
  };

  useEffect(() => {
    fetchRequests();
  }, []);

  return (
    <div className="overflow-auto bg-gray-100 max-h-screen">
      <div className=" mx-auto bg-white shadow-lg rounded-lg p-6">
        <h1 className="text-2xl font-bold text-gray-800 mb-6">
          Manage Withdrawal Requests
        </h1>

        <div className="bg-white rounded shadow overflow-hidden">
          <div className="overflow-y-auto h-full max-h-[620px]">
            <table className="min-w-full table-auto">
              <thead className="bg-gray-200 sticky top-0">
                <tr>
                  <th className="px-6 py-3 font-medium text-gray-700 border-b">
                    Sr.no
                  </th>
                  <th className="px-6 py-3 font-medium text-gray-700 border-b">
                    User
                  </th>
                  <th className="px-6 py-3 font-medium text-gray-700 border-b">
                    Amount
                  </th>
                  <th className="px-6 py-3 font-medium text-gray-700 border-b">
                    Type
                  </th>
                  <th className="px-6 py-3 font-medium text-gray-700 border-b">
                    Mode
                  </th>
                  <th className="px-6 py-3 font-medium text-gray-700 border-b">
                    Date Time
                  </th>
                  <th className="px-6 py-3 font-medium text-gray-700 border-b">
                    Status
                  </th>
                  <th className="px-6 py-3 font-medium text-gray-700 border-b">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentRequests.length > 0 ? (
                  currentRequests.map((request, index) => (
                    <tr
                      key={request._id}
                      className="bg-white hover:bg-gray-50 border-b"
                    >
                      <td className="px-3 py-2">{startIndex + index + 1}</td>
                      <td className="px-3 py-2 text-sm whitespace-nowrap">
                        {request?.userDetails?.name || "N/A"}
                      </td>
                      <td className="px-3 py-2">
                        {request.amount - request.amount * 0.1}
                      </td>
                      <td className="px-3 py-2">{request.withdrawalType}</td>
                      <td className="px-3 py-2">
                        {request.withdrawalDetails && (
                          <div>
                            {Object.entries(request.withdrawalDetails).map(
                              ([key, value], idx) => (
                                <p key={idx} className="text-gray-800">
                                  <strong>{key}:</strong> {value}
                                </p>
                              )
                            )}
                          </div>
                        )}
                      </td>
                      <td className="px-3 py-2">
                        {new Date(request.createdAt).toLocaleString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false,
                        })}
                      </td>
                      <td className="px-3 py-2">
                        {request.status === 1
                          ? "Pending"
                          : request.status === 2
                          ? "Approved"
                          : "Rejected"}
                      </td>
                      <td className="px-3 py-2 flex space-x-2">
                        {request.status === 1 && (
                          <>
                            <button
                              className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
                              onClick={() => setSelectedRequest(request)}
                            >
                              Approve
                            </button>
                            <button
                              className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
                              onClick={() => setSelectedRequest(request)}
                            >
                              Reject
                            </button>
                          </>
                        )}
                        {request.status !== 1 && (
                          <span className="text-gray-400">Action Taken</span>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center py-4 text-gray-500">
                      No requests found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex justify-between items-center mb-4">
          <div className="space-x-4">
            <button
              className={`px-4 py-2 rounded-md ${
                filterStatus === "all"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200"
              }`}
              onClick={() => setFilterStatus("all")}
            >
              All
            </button>
            <button
              className={`px-4 py-2 rounded-md ${
                filterStatus === "pending"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200"
              }`}
              onClick={() => setFilterStatus("pending")}
            >
              Pending
            </button>
            <button
              className={`px-4 py-2 rounded-md ${
                filterStatus === "approved"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200"
              }`}
              onClick={() => setFilterStatus("approved")}
            >
              Approved
            </button>
          </div>
          <div className="p-3">
            <button
              className={`px-4 py-2 rounded-md ${
                currentPage > 1
                  ? "bg-blue-500 text-white hover:bg-blue-600"
                  : "bg-gray-300 text-gray-500"
              }`}
              onClick={() => handlePageChange("back")}
              disabled={currentPage === 1}
            >
              Back
            </button>
            <button
              className={`px-4 py-2 m-3 rounded-md ${
                currentPage < totalPages
                  ? "bg-blue-500 text-white hover:bg-blue-600"
                  : "bg-gray-300 text-gray-500"
              }`}
              onClick={() => handlePageChange("next")}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>

        {selectedRequest && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded-lg shadow-lg p-6 max-w-lg sm:max-w-xl w-full">
              <h2 className="text-xl font-bold mb-4">Request Details</h2>
              <div className="mb-4">
                <p>
                  <strong>User:</strong>{" "}
                  {selectedRequest.userDetails.name || "N/A"}
                </p>
                <p>
                  <strong>Amount:</strong> {selectedRequest.amount}
                </p>
                <p>
                  <strong>Type:</strong> {selectedRequest.withdrawalType}
                </p>
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  placeholder="Transaction ID"
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-400 mb-2"
                  value={actionData.transactionId}
                  onChange={(e) =>
                    setActionData((prev) => ({
                      ...prev,
                      transactionId: e.target.value,
                    }))
                  }
                />
                <input
                  type="date"
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-400 mb-2"
                  value={actionData.transactionDate}
                  onChange={(e) =>
                    setActionData((prev) => ({
                      ...prev,
                      transactionDate: e.target.value,
                    }))
                  }
                />
                <textarea
                  placeholder="Remark"
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-400 mb-2"
                  value={actionData.remark}
                  onChange={(e) =>
                    setActionData((prev) => ({
                      ...prev,
                      remark: e.target.value,
                    }))
                  }
                ></textarea>
              </div>
              <div className="flex justify-end space-x-2">
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                  onClick={() => handleAction(selectedRequest._id, 2)}
                >
                  Approve
                </button>
                <button
                  className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
                  onClick={() => handleAction(selectedRequest._id, 3)}
                >
                  Reject
                </button>
                <button
                  className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-400"
                  onClick={() => setSelectedRequest(null)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminWithdrawal;
