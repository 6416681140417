import axios from "axios";
import { getStorageValue } from "../services/Miscellaneous/LocalStorageServices";
import { emitter } from "./EventEmitter";

const getToken = async () => {
  try {
    const response =  getStorageValue('token');
    let token
    if(response){
         token = response; // Assuming the 'response' itself is the token

    }
    else{
        token=""
    }
    return token;
  } catch (error) {
    console.log(error, 'Error fetching token from AsyncServices');
    return null;
  }
};

axios.interceptors.request.use(
  async (config) => {
    // Get token from AsyncStorage
    const token = await getToken();
    if (token !=="") {
      // Set the "Authorization" header with the Bearer token
      config.headers['x-auth-token'] = `${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // console.log(r);
    // Do something with successful response data
    return response.data;
  },
  function (error) {
    // Do something with error response
    if (error.response && error.response.data && error.response.data.error && error.response.data.error.message === "Token has been expired. Kindly Relogin!") {
      emitter.emit('logout');
    }
    return Promise.reject(error);
  }
);

export default axios;