import React, { useEffect, useState } from 'react';
import { getRewardlistservice } from '../../../services/auth/AuthServices';

function RewardMarquee() {
  const [rewardList, setRewardList] = useState([]);

  // Fetch rewards service
  const fetchRewardList = async () => {
    try {
      const response = await getRewardlistservice(); // Fetch rewards
      setRewardList(response.result.data);
    } catch (error) {
      console.error('Error fetching reward list:', error);
    }
  };

  useEffect(() => {
    fetchRewardList();
  }, []);

  // Random color generator
  const getRandomColor = () => {
    const colors = [
      'bg-red-500',
      'bg-blue-500',
      'bg-green-500',
      'bg-yellow-500',
      'bg-purple-500',
      'bg-pink-500',
      'bg-teal-500',
    ];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  return (
    <div className="w-full overflow-hidden py-8">
      <h2 className="text-4xl font-extrabold text-black text-center mb-8">
        All-Time Achiever List
      </h2>
      <div className="flex animate-marquee gap-8">
        {rewardList.map((reward, index) => (
          <div
            key={reward._id}
            className={`flex-none w-64 p-6 rounded-lg shadow-lg ${getRandomColor()} transform hover:scale-105 transition relative`}
          >
            {/* Star Shape */}
            <div className="absolute -top-6 -left-6 bg-yellow-300 text-black text-xl font-extrabold w-16 h-16 flex justify-center items-center rounded-full shadow-lg star-shape">
              {index + 1}
            </div>
            {/* Content */}
            <div className="mt-10">
              <h3 className="text-xl font-semibold text-white">{reward.name}</h3>
              <p className="text-sm text-gray-200">
                Email: {reward.email.slice(0, 4)}*****
              </p>
              <p className="text-sm text-gray-200">
                Mobile: {`${reward.mobile.toString().slice(0, 4)}******`}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Style for marquee effect */}
      <style jsx>{`
        .animate-marquee {
          display: flex;
          animation: marquee 25s linear infinite;
        }
        @keyframes marquee {
          0% {
            transform: translateX(100%);
          }
          100% {
            transform: translateX(-100%);
          }
        }
        .star-shape {
          clip-path: polygon(
            50% 0%,
            61% 35%,
            98% 35%,
            68% 57%,
            79% 91%,
            50% 70%,
            21% 91%,
            32% 57%,
            2% 35%,
            39% 35%
          );
        }
      `}</style>
    </div>
  );
}

export default RewardMarquee;
