import React, { useEffect, useState } from "react";
import "../../css/authFile.css";
import bannerVedio from "../../assets/images/bannerVideo.mp4";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginAction } from "../../redux/action/userAction/authAction/AuthAction";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../../assets/logo.png";
import { userLoginServices } from "../../services/auth/AuthServices";
import { setStorageValue } from "../../services/Miscellaneous/LocalStorageServices";
import { errorHandler } from "../../services/Miscellaneous/ErrorHandlerServices";
import { getUserDetailService } from "../../services/user/UserServices";
import { errorTost, successTost } from "../../utils/Helper";

export const LoginView = ({ loginAction, setIsLogin }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    userId: "",
    password: "",
  });

  const onChangeHandlder = (e) => {
    let { name, value } = e.target;
    setFormData((pre) => ({ ...pre, [name]: value }));
  };

  const onSubmitHandler = async () => {
    // setIsLogin(true)
    // navigate("/");

    if (!formData.userId) {
      toast.error("Please enter user name");
      return;
    }

    if (!formData.password) {
      toast.error("Please enter password");
      return;
    }
    try {
      let response = await userLoginServices(formData);

      setStorageValue("token", response?.result.token);
      setStorageValue("userdetail", response?.result)
      verifyUserHandler(response);
      // setIsLogin(true)
      // navigate('/Payment')
      // setFormData(response.data?.resulttoken)
    } catch (error) {
      errorTost(error)
      console.log(error);
    }
  };

  const verifyUserHandler = async (res) => {
    try {
      let response = await getUserDetailService(res?.result?.userId);
      if (response.result.status === 2) {
        setIsLogin(true);
        setStorageValue("amount", response?.result?.joiningAmount);
        navigate("/Payment");
      } else {
        setIsLogin(true);
        navigate("/user");
      }
    } catch (error) {
      console.log(error);
      errorHandler(error);
    }
  };

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <div className="h-screen w-screen  flex ">
      <div className="w-full hidden md:w-2/4 h-screen md:relative md:flex flex-col ">
        <video
          className="h-screen w-full absolute top-0 left-0 object-cover"
          src={bannerVedio}
          controls={false}
          muted
          autoPlay={"autoplay"}
          preLoad="auto"
          loop
        ></video>

        <div className="absolute flex-col bg-transparent top-0 bg-white bg-opacity-60   flex justify-center items-center h-screen w-full">
          <img src={logo} className=" mr-3 " alt="Loading" />
          <p className="text-black  font-semibold mt-2 z-50">
            Luxury Living with BRfarms
          </p>
        </div>
      </div>
      <div className="w-[100%] p-4 md:p-0 md:w-2/4 h-screen md:bg-userTheme flex justify-center items-center flex-col">
        <video
          className="h-screen w-full  absolute md:hidden top-0 left-0 object-cover"
          src={bannerVedio}
          controls={false}
          muted
          autoPlay={"autoplay"}
          preload="auto"
          loop
        ></video>
        <div className="w-full md:w-[30rem] mx-auto flex flex-col  items-center justify-center">
          
          <p className="text-white text text-3xl font-semibold z-50 font-medium  md:text-[2rem] ">
            Nice to see you!
          </p>
          <p className="text-white text font-normal my-2 z-50">
            Enter your email and password to sign in
          </p>
        </div>
        <div className=" bg-gray-400 mt-7 w-full md:w-[30rem] rounded-md bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10 ">
          <form
            // onSubmit={onSubmitHandler}
            class="w-full mx-auto bg-transparent p-5  backdrop-blur-lg	"
          >
            <div class="mb-5">
              <label
                for="email"
                class="block mb-2 text-sm font-medium text-white dark:text-white"
              >
                Your email
              </label>
              <input
                onChange={onChangeHandlder}
                name="userId"
                type="email"
                id="email"
                class=" text-gray-900 text-sm bg-userTheme text-white rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                placeholder="Enter your email"
                required
              />
            </div>
            <div class="mb-5">
              <label
                for="password"
                class="block mb-2 text-sm font-medium text-white dark:text-white"
              >
                Your password
              </label>
              <input
                onChange={onChangeHandlder}
                name="password"
                type="password"
                id="password"
                class=" text-gray-900 text-sm bg-userTheme text-white rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                required
                placeholder="Enter your password"
              />
            </div>
            <div class="flex items-start mb-5">
              <NavLink to={"/forgetPassword"}>
                <label
                  for="remember"
                  class="ms-2 text-sm  underline text-white font-bold "
                >
                  Forget Password ?
                </label>
              </NavLink>
            </div>
            <button
              onClick={onSubmitHandler}
              type="button"
              class="text-white w-full bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm   px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Submit
            </button>
          </form>
        </div>

        <div className="w-full md:w-[30rem] mt-3">
          <p className="text-white text font-light my-2">
            Don't have an account ?
            <span
              onClick={() => navigate("/signup")}
              className="mx-1 font-semibold cursor-pointer"
            >
              Sign up
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

LoginView.propTypes = {
  // second: PropTypes.third
  loginAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { loginAction })(LoginView);
