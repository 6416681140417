import React from "react";
import { Outlet } from "react-router-dom";

import Sidebar from "../../../components/admin/Sidebar";

const AdminLayout = () => {
  return (
    <div>
      <Sidebar />
      <div class=" sm:ml-64 p-4  h-screen overflow-hidden bg-bgColor pt-20 ">
        <Outlet />
      </div>
    </div>
  );
};

export default AdminLayout;
