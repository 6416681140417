import React from "react";
import backgroundVideo from "../../../assets/images/bannerVideo.mp4"; // Add your video path here
import contactImg from "../../../assets/images/contact.png"; // Replace with your contact image

function Contact() {
  return (
    <div>
    
      {/* Fixed Video Background */}
      <div className="fixed top-0 left-0 w-full h-screen -z-10">
        <video
          autoPlay
          loop
          muted
          className="absolute inset-0 w-full h-full object-cover"
        >
          <source src={backgroundVideo} type="video/mp4" />
        </video>
        {/* Skyblue Blur Overlay */}
        <div className="absolute inset-0 bg-white bg-opacity-10 backdrop-blur-md"></div>
      </div>

      {/* Contact Content */}
      <div className="relative container mx-auto px-5 py-20 space-y-20">
        <div className="text-center">
          <h1 className="text-5xl md:text-6xl font-bold bg-gradient-to-r from-shuttle to-white text-transparent bg-clip-text">
            Contact Us
          </h1>
        </div>

        <div className="flex flex-col md:flex-row items-center space-y-10 md:space-y-0">
          {/* Left Column: Text Content */}
          <div className="w-full md:w-1/2 text-white space-y-6">
            <h2 className="text-3xl font-semibold text-golden">Get in Touch</h2>
            <div className="space-y-4">
              <h3 className="text-2xl font-bold">Contact Information:</h3>
              <ul className="list-disc ml-5 text-lg font-thin">
                <li>Email: contact@BRFARMS.com</li>
                <li>Phone: +1 283-432-5879</li>
              </ul>
            </div>
            <div className="space-y-4">
              <h3 className="text-2xl font-bold">Customer Support:</h3>
              <p className="text-lg font-thin">
                Our customer support team is available during the following hours:
              </p>
              <ul className="list-disc ml-5 text-lg font-thin">
                <li>Monday-Friday: 9:00 AM - 5:00 PM</li>
                <li>Weekends: Closed</li>
              </ul>
              <p className="text-lg font-thin">
                Response time: We typically respond to inquiries within 24-48 hours.
              </p>
            </div>
            <button className="mt-5 px-6 py-3 bg-golden text-white font-semibold rounded-full hover:bg-white hover:text-golden transition-colors duration-300">
              Contact Us Now
            </button>
          </div>

          {/* Right Column: Image */}
          <div className="w-full md:w-1/2 flex justify-center">
            <img
              src={contactImg}
              alt="Contact"
              className="rounded-lg w-full md:w-3/4"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
