import { errorHandler } from "../../../../services/Miscellaneous/ErrorHandlerServices";
import { adminDashBoardServices } from "../../../../services/admin/AdminServices";
import { userSignUpServices } from "../../../../services/auth/AuthServices";
import { ADMIN_DASHBOARD_DATA } from "../../../../utils/Types";

//#region Dasboard Data Actions
export const dashboardDataAction = () => async (dispatch) => {
  try {
    // Call Service
    let response = await adminDashBoardServices();
    console.log(response);
    dispatch({ type: ADMIN_DASHBOARD_DATA, payload: response?.result })
  } catch (err) {
    errorHandler(err);
  }
};
// #endregion