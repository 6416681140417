import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";

import { Provider } from "react-redux";
import store from "./redux/store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "@material-tailwind/react";

const root = ReactDOM.createRoot(document.getElementById("root"));

const RootComponent = () => {
  useEffect(() => {

  }, []);

  return (
    <Provider store={store}>
      {/* Wrap ThemeProvider Correctly */}
      <ThemeProvider>
        <ToastContainer />
        <App />
      </ThemeProvider>
    </Provider>
  );
};


root.render(<RootComponent />);

