import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { DashBoardCard } from '../../../components/common/DashBoardCard'
import { dashboardDataAction } from '../../../redux/action/adminAction/DashboardAction/DashBoardDataAction'

export const DashboardView = ({dashboardDataAction,dashboardState}) => {
 
  useEffect(() => {
    dashboardDataAction()
  }, [])
  
  
  return (
    <div className='h-screen w-full overflow-scroll pb-10'>
      <div className='flex flex-wrap justify-between  pt-8'>
        <DashBoardCard heading={'Total User'} value={dashboardState?.data?.usersCount} icon="fa-sharp fa-solid fa-users" />
        <DashBoardCard heading={'Total Bussiness'} value={dashboardState?.data?.totalBusiness} icon="fa-solid fa-business-time" />
        <DashBoardCard heading={'Total Withdraw'} value={dashboardState?.data?.totalWithdraw} icon="fa-solid fa-money-bill-transfer"/> 
        <DashBoardCard heading={'Wallet Amount'} value={parseFloat(dashboardState?.data?.currentWalletAmount).toFixed(2)} icon="fa-solid fa-wallet"/> 
        <DashBoardCard heading={'Today Bussiness'} value={dashboardState?.data?.todayTotalBusiness} icon="fa-solid fa-calendar-week"/> 
        <DashBoardCard heading={'Current Month Business'} value={dashboardState?.data?.currentMonthTotalBusiness} icon="fa-solid fa-calendar-days"/>
      </div>


    </div>
  )
}

DashboardView.propTypes = {
//   second: PropTypes.third
dashboardDataAction:PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  dashboardState:state.dashboardState
})

export default connect(mapStateToProps, {dashboardDataAction})(DashboardView)