import React, { useEffect, useState } from "react";

const NormalBox = ({ name, textData, icon }) => {
  const [textColor, setTextColor] = useState('');
  const [profitLoss, setProfitLoss] = useState('');
  const [text, setText] = useState('');
 

  return (
    <div className="flex flex-col md:flex-row justify-between items-center px-6 py-7 m-4 bg-gradient-to-l from-transparent to-blue-900 rounded-2xl border-[0.5px] border-white/10 shadow-xl">
      <div>
        <p className="text-gray-300 text-sm">{name}</p>
        {/* <span className="text-white lg:text-2xl font-bold">$ {money}</span>{" "} */}
        <span className={`text-green-500 text-2xl font-semibold text-white`}>
          {profitLoss}{textData}
        </span>
      </div>
      <div>
        <div className="bg-blue-600 lg:p-3 p-2 lg:rounded-2xl rounded-xl">{icon}</div>
      </div>
    </div>
  );
};

export default NormalBox;
