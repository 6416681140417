import React, { useEffect, useState } from "react";
import { errorHandler } from "../../../services/Miscellaneous/ErrorHandlerServices";
import { getDirectIncomeListServices } from "../../../services/user/UserServices";
import { getDateAndTimeByTimestamp } from "../../../utils/DateAndTimeFunctions";

function DirectIncome() {
  const [totalIncomeList, setTotalIncomeList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const refferralsList = async () => {
    try {
      let response = await getDirectIncomeListServices();
      setTotalIncomeList(response.result);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    refferralsList();
  }, []);

  // Pagination logic
  const totalPages = Math.ceil(totalIncomeList.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = totalIncomeList.slice(startIndex, startIndex + itemsPerPage);

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="h-screen overflow-hidden">
      <div className="grid lg:grid-cols-1 grid-cols-1 px-4 py-5 m-4 bg-gradient-to-l from-transparent to-blue-900 rounded-2xl border-[0.5px] border-white/10 shadow-xl">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-white uppercase bg-userGradiant">
              <tr>
                <th scope="col" className="p-4">Sl</th>
                <th scope="col" className="px-6 py-3">Member Id</th>
                <th scope="col" className="px-6 py-3">Plan Amount</th>
                <th scope="col" className="px-6 py-3">Commission</th>
                <th scope="col" className="px-6 py-3">Level</th>
                <th scope="col" className="px-6 py-3">Commission Type</th>
                <th scope="col" className="px-6 py-3">Date</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((ele, ind) => (
                <tr key={ind} className="border-b hover:bg-blue-900">
                  <td className="w-4 p-4">{startIndex + ind + 1}</td>
                  <td className="px-6 py-4 text-white">{ele?.newMemberReferralCode}</td>
                  <td className="px-6 py-4 text-white">{ele?.totalAmount}</td>
                  <td className="px-6 py-4 text-white">{ele?.commisionAmount}</td>
                  <td className="px-6 py-4 text-white">{ele?.commisionLevel}</td>
                  <td className="px-6 py-4 text-white">
                    {ele?.commisionType === 1
                      ? "Direct Referral"
                      : ele?.commisionType === 2
                      ? "Level Income"
                      : "Unknown Type"}
                  </td>
                  <td className="px-6 py-4 text-white">
                    {getDateAndTimeByTimestamp(ele?.timeStamp)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination Controls */}
          {totalIncomeList.length > itemsPerPage && (
            <nav className="flex items-center justify-between py-4 text-white">
              <button
                onClick={handlePrevious}
                disabled={currentPage === 1}
                className={`px-4 py-2 rounded-lg ${
                  currentPage === 1 ? "bg-gray-500 cursor-not-allowed" : "bg-blue-600"
                }`}
              >
                Previous
              </button>
              <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm">
                {Array.from({ length: totalPages }, (_, index) => (
                  <li key={index}>
                    <button
                      onClick={() => handlePageClick(index + 1)}
                      className={`px-3 h-8 leading-tight border ${
                        currentPage === index + 1
                          ? "bg-blue-500 text-white"
                          : "bg-white text-gray-500 hover:bg-gray-100 hover:text-gray-700"
                      }`}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
              </ul>
              <button
                onClick={handleNext}
                disabled={currentPage === totalPages}
                className={`px-4 py-2 rounded-lg ${
                  currentPage === totalPages ? "bg-gray-500 cursor-not-allowed" : "bg-blue-600"
                }`}
              >
                Next
              </button>
            </nav>
          )}
        </div>
      </div>
    </div>
  );
}

export default DirectIncome;