import { ADMIN_DASHBOARD_DATA } from "../../utils/Types";

const initialState = {
  data: null,
  loading: false,
};

export const dashboardState = (state = initialState, action) => {
  // Destructure Action
  const { type, payload } = action;

  // console.log(payload);
  switch (type) {
    // Default Case
    case ADMIN_DASHBOARD_DATA: {
      return { ...state, data: payload };
    }
    default: {
      return state;
    }
  }
};
