// Routes.js

import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes,
} from "react-router-dom";
import LoginView from "../view/auth/LoginView";
import PublicView from "../view/public/PublicView";
import AdminLoginView from "../view/auth/AdminLoginView";

import {
  getStorageValue,
  removeStorageValue,
} from "../services/Miscellaneous/LocalStorageServices";
import SignUpView from "../view/auth/SignUpView";
// import AdminHomeRoute from "./AdminHomeRoute";
import AdminLayout from "../view/admin/Layout/AdminLayout";
import DashboardView from "../view/admin/Dashboard/DashboardView";
import RegisterNewUser from "../view/admin/Actions/RegisterNewUser";
import ActivateUser from "../view/admin/Actions/ActivateUser";
import UserTree from "../view/admin/UserTree/UserTree";
import RequestActivation from "../view/admin/Actions/RequestActivation";
import Transaction from "../view/user/Transcation/Transaction";
import BussinesReport from "../view/admin/BussinessReport/BussinesReport";
import InvestmentReport from "../view/admin/Investment/InvestmentReport";
import UserLayout from "../view/admin/Layout/UserLayout";
import UserHome from "../view/user/Dashboard/UserHome";
import Deposit from "../view/admin/Actions/Deposit";
import Withdraw from "../view/admin/Actions/Withdraw";
import PasswordReset from "../view/admin/Actions/PasswordReset";
import ChangeEmail from "../view/admin/Actions/ChangeEmail";
import BlockUser from "../view/admin/Actions/BlockUser";
import Referrals from "../view/user/Team/Referrals";
import MyBussiness from "../view/user/MyBussiness/MyBussiness";
import TotalIncome from "../view/user/IncomeDetails/TotalIncome";
import DirectIncome from "../view/user/IncomeDetails/DirectIncome";
import LevelIncome from "../view/user/IncomeDetails/LevelIncome";
import UserTransaction from "../view/user/Transcation/Transaction";
import ChnagePassword from "../view/user/Setting/ChnagePassword";
import ForgetpasswordView from "../view/auth/ForgetpasswordView";
import Payment from "../view/user/Payment/Payment";
import { emitter } from "../utils/EventEmitter";
import RSSUsage from "../components/common/RSSUsage";
import About from "../view/public/pages/About";
import Services from "../view/public/pages/Services";
import Contact from "../view/public/pages/Contact";
import MemberTree from "../view/admin/UserTree/MemberTree";
import TeamList from "../view/user/Team/TeamList";
import Rewards from "../view/user/Rewards/Rewards";
import Profile from "../view/user/Profile/Profile";
import Transactions from "../view/admin/Transaction/Transaction";
import UserWithdrawal from "../view/user/Withdarw/WithdarwRequest";
import AdminWithdrawal from "../view/admin/Withdrawal/WithdrawalRequsts";
import RewardList from "../view/admin/UserTree/RewardList";
const MainRoutes = ({ isAuthenticated }) => {
  const [isLogin, setIsLogin] = useState(false);
  const [userType, setuserType] = useState(null);

  const checkToken = () => {
    let token = getStorageValue("token");
    if (token) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
      // window.location.href = "/auth";
    }
  };

  useEffect(() => {
    emitter.on("logout", () => {
      setIsLogin(false);
      localStorage.clear()
      window.location.href = "/auth";
    });
  }, []);

  useEffect(() => {
    checkToken();
    // removeStorageValue('token')
  }, []);

  return (
    <Router>
      {/* Public Route */}
      {/* {userType == null && ( */}
      {!isLogin && (
        <Routes>
          <Route path="/" element={<PublicView />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      )}
     
      <Routes>
        
        <Route path="/auth" element={<LoginView setIsLogin={setIsLogin} />} />
        <Route path="/signup" element={<SignUpView />} />
        <Route path="/forgetPassword" element={<ForgetpasswordView />} />
        <Route path="/Payment" element={<Payment />} />


      </Routes>
      {isLogin && (
        <Routes>

          <Route path="/user" element={<UserLayout setIsLogin={setIsLogin} />}>
            <Route index element={<UserHome />} />
            <Route path="Referrals" element={<Referrals />} />
            <Route path="TeamList" element={<TeamList />} />
            <Route path="Rewards" element={<Rewards />} />
            {/* <Route path="/Member" element={<Referrals />} /> */}
            <Route path="MyBussiness" element={<MyBussiness />} />
            <Route path="TotalIncome" element={<TotalIncome />} />
            <Route path="DirectIncome" element={<DirectIncome />} />
            <Route path="LevelIncome" element={<LevelIncome />} />
            <Route path="Transaction" element={<UserTransaction />} />
            <Route path="ChnagePassword" element={<ChnagePassword />} />
            <Route path="Payment" element={<Payment />} />
            <Route path="RefferAndEarn" element={<RSSUsage />} />
            <Route path="memberTree" element={<MemberTree />} />
            <Route path="profile" element={<Profile />} />
            <Route path="withdrawalrequest" element={<UserWithdrawal />} />


          </Route>
        </Routes>
      )}
      {/* )} */}

      {/* Admin Auth Route */}
      {/* {userType == 2 && ( */}
      <Routes>
        <Route path="/cashControl" element={<AdminLoginView />} />
        {/* <Route path="/admin/register" element={<AdminRegisterView />} /> */}
      </Routes>

      {/* {
        userType == 3 && */}
      <Routes>
        <Route path="/admin" element={<AdminLayout />}>
          <Route index element={<DashboardView />} />
          <Route path="register-new-user" element={<RegisterNewUser />} />
          <Route path="activate-user" element={<ActivateUser />} />
          <Route path="deposit-user" element={<Deposit />} />
          <Route path="withdraw-user" element={<Withdraw />} />
          <Route path="password-reset-user" element={<PasswordReset />} />
          <Route path="change-email-user" element={<ChangeEmail />} />
          <Route path="block-reset-user" element={<BlockUser />} />
          <Route path="allTransaction" element={<Transactions />} />
          <Route path="user-tree" element={<UserTree />} />
          <Route path="useractivation-request" element={< RequestActivation />} />
          <Route path="BussinessReport" element={<BussinesReport />} />
          <Route path="InvestmentReport" element={<InvestmentReport />} />
          <Route path="withdrawalRequests" element={<AdminWithdrawal />} />
          <Route path="user-rewardlist" element={<RewardList />} />

          
        </Route>
      </Routes>

      {/* } */}
    </Router>
  );
};

export default MainRoutes;
