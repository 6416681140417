import React, { useEffect, useState } from "react";
import { mybussinessListServices } from "../../../services/user/UserServices";
import { errorHandler } from "../../../services/Miscellaneous/ErrorHandlerServices";
import { getDateAndTimeByTimestamp } from "../../../utils/DateAndTimeFunctions";

function MyBussiness() {
  const [bussinessList, setBussinesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;

  const refferralsList = async () => {
    try {
      let response = await mybussinessListServices();
      setBussinesList(response.result);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    refferralsList();
  }, []);

  // Pagination logic
  const totalPages = Math.ceil(bussinessList.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = bussinessList.slice(startIndex, startIndex + itemsPerPage);

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="">
      {/* Search Table */}
      <div className="grid lg:grid-cols-1 grid-cols-1 px-4 py-5 m-4 bg-gradient-to-r from-transparent to-blue-900 rounded-2xl border-[0.5px] border-white/10 shadow-xl">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg scroll">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-white uppercase bg-userGradiant">
              <tr>
                <th scope="col" className="p-4">
                  Sl
                </th>
                <th scope="col" className="px-6 py-3">
                  Member Id
                </th>
                <th scope="col" className="px-6 py-3">
                  Plan Amount
                </th>
                <th scope="col" className="px-6 py-3">
                  Commission
                </th>
                <th scope="col" className="px-6 py-3">
                  Level
                </th>
                <th scope="col" className="px-6 py-3">
                  Commission Type
                </th>
                <th scope="col" className="px-6 py-3">
                  Date
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((ele, ind) => (
                <tr key={ind} className="border-b hover:bg-blue-900">
                  <td className="w-4 p-4">{startIndex + ind + 1}</td>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-white whitespace-nowrap dark:text-white"
                  >
                    {ele?.newMemberReferralCode}
                  </th>
                  <td className="px-6 py-4 text-white">{ele?.totalAmount}</td>
                  <td className="px-6 py-4 text-white">{ele?.commisionAmount}</td>
                  <td className="px-6 py-4 text-white">{ele?.commisionLevel}</td>
                  <td className="px-6 py-4 text-white">
                    {ele?.commisionType === 1
                      ? "Direct Referral"
                      : ele?.commisionType === 2
                      ? "Level Income"
                      : ele?.commisionType === 3
                      ? "Weekly ROI"
                      : ele?.commisionType === 4
                      ? "Level Weekly ROI"
                      : "Unknown Type"}
                  </td>
                  <td className="px-6 py-4 text-white">
                    {getDateAndTimeByTimestamp(ele?.timeStamp)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* Pagination Controls */}
          <div className="flex justify-between items-center mt-4 px-4">
            <button
              onClick={handlePrevious}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-lg ${
                currentPage === 1 ? "bg-gray-500 cursor-not-allowed" : "bg-blue-600"
              }`}
            >
              Previous
            </button>
            <span className="text-white">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNext}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-lg ${
                currentPage === totalPages
                  ? "bg-gray-500 cursor-not-allowed"
                  : "bg-blue-600"
              }`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyBussiness;