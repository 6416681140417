const AccountDetailsBox = ({ accountNumber, bankName, ifscCode, icon }) => {
    return (
      <div className="flex flex-col md:flex-row justify-between items-center px-6 py-7 m-4 bg-gradient-to-l from-transparent to-blue-900 rounded-2xl border-[0.5px] border-white/10 shadow-xl">
        {/* Account Details */}
        <div>
          <p className="text-gray-300 text-sm mb-2">Bank Details</p>
          <div className="flex flex-col">
            <span className="text-white text-2xl font-bold">{accountNumber || "N/A"}</span>
            <span className="text-gray-300 text-sm">{bankName || "Bank Name: N/A"}</span>
            <span className="text-gray-300 text-sm">{ifscCode || "IFSC Code: N/A"}</span>
          </div>
        </div>
  
        {/* Icon */}
        <div>
          <div className="bg-blue-600 lg:p-3 p-2 lg:rounded-2xl rounded-xl">{icon}</div>
        </div>
      </div>
    );
  };
  
  export default AccountDetailsBox;
  