export const getDDMMYYYYByTimestamp = (timestamp) => {
    let d = new Date(timestamp)
    let date = d.getDate()
    let month = d.getMonth() + 1
    let year = d.getFullYear()

    return `${date}-${month}-${year}`
}

export const convertTimestampToMonthAndYear = (timestamp) => {
        const date = new Date(timestamp);
        const month = date.toLocaleString("default", { month: "long" });
        const year = date.getFullYear();
        return `${month} ${year}`;
}

export const getDateAndTimeByTimestamp = (timestamp) => {
    let d = new Date(timestamp)
    let day = d.getDate()
    if (day < 10) {
        day = '0' + day
    }
    let month = d.getMonth() + 1
    if (Number(month) < 10) {
        month = '0' + month
    }
    let year = d.getFullYear()
    let hr = d.getHours()
    if (Number(hr) < 10) {
        hr = '0' + hr
    }
    let min = d.getMinutes()
    if (Number(min) < 10) {
        min = '0' + min
    }
    let sec = d.getSeconds()
    if (Number(sec) < 10) {
        sec = '0' + sec
    }

    return `${day}-${month}-${year} ${hr}:${min}:${sec}`
}