import { useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import Logo from "../../../assets/logo.png";
import { useNavigate } from "react-router-dom";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const routes = [
    { id: "home", name: "Homes", path: "/" },
    { id: "about", name: "About", path: "/about" },
    { id: "services", name: "Services", path: "/services" },
    { id: "contact", name: "Contact Us", path: "/contact" },
  ];

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <div className="relative">
      {/* Navbar */}
      <nav className="fixed top-0 left-0 w-full z-[1000] p-4">
        <div className="flex justify-between items-center">
          {/* Conditional Logo Rendering */}
          {!isOpen && (
            <a href="/" className="relative group">
              <div className="absolute inset-0 bg-white bg-opacity-80 rounded-lg blur-lg group-hover:blur-xl transition-all duration-500"></div>
              <img
                src={Logo}
                alt="Logo"
                className="relative h-20 w-auto z-10"
              />
            </a>
          )}

          {/* Hamburger Button */}
          <button
            onClick={toggleMenu}
            className={`text-4xl md:text-3xl focus:outline-none ${
              isOpen ? "text-white" : "text-black"
            }`}
            aria-label="Toggle Menu"
          >
            {isOpen ? (
              // Close Icon (White)
              <svg
                className="w-8 h-8" // Increased size for better visibility
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              // Hamburger Icon (Black)
              <svg
                className="w-8 h-8" // Increased size for better visibility
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            )}
          </button>
        </div>
      </nav>

      {/* Full-Screen Menu */}
      <div
        className={`fixed inset-0 z-[999] bg-black bg-opacity-95 text-white transform duration-500 ${
          isOpen ? "translate-y-0" : "-translate-y-full"
        }`}
      >
        <div className="flex flex-col items-center justify-center h-full space-y-6 text-center">
          {/* Logo in Full-Screen Menu */}
          <div className="relative group">
            <div className="absolute inset-0 bg-white bg-opacity-80 rounded-lg blur-lg group-hover:blur-xl transition-all duration-500"></div>
            <img src={Logo} alt="Logo" className="relative h-20 z-10" />
          </div>

          {/* Menu Items */}
          {routes.map((route) => (
            <button
              key={route.id}
              onClick={() => {
                navigate(route.path);
                toggleMenu();
              }}
              className="text-3xl md:text-4xl font-semibold hover:text-golden transition-colors duration-300 cursor-pointer"
            >
              {route.name}
            </button>
          ))}

          {/* Call-to-Action Buttons */}
          <div className="flex space-x-4 mt-8">
            <button
              onClick={() => {
                navigate("/auth");
                toggleMenu();
              }}
              className="mt-5 px-6 py-3 bg-golden text-white font-semibold rounded-full hover:bg-white hover:text-golden transition-colors duration-300"
            >
              Login
            </button>
            <button
              onClick={() => {
                navigate("/signup");
                toggleMenu();
              }}
               className="mt-5 px-6 py-3 bg-golden text-white font-semibold rounded-full hover:bg-white hover:text-golden transition-colors duration-300"
            >
              Signup
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
