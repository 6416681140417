

import MainRoutes from "./routes/Route";

function App() {
  return (
    <div className="relative">
      <MainRoutes />
    </div>
  );
}

export default App;
