import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../../../components/user/Sidebar";

const UserLayout = ({ setIsLogin }) => {
  const [open,setOpen]=useState(true)

  useEffect(() => {
		const handleResize = () => {
		  // Check the viewport width and update the state accordingly
		  setOpen(window.innerWidth >= 1024?true:false);
		};
		// Initial check on mount
		handleResize();
	
		// Attach the event listener
		window.addEventListener('resize', handleResize);
	
		// Cleanup the event listener on component unmount
		return () => {
		  window.removeEventListener('resize', handleResize);
		};
	  }, []); 
  return (
    <div>
      <Sidebar setIsLogin={setIsLogin} open={open} setOpen={setOpen} />
      <div className="userBlueBg sm:ml-64 pt-20">
        <Outlet />
      </div>
    </div>
  );
};

export default UserLayout;
