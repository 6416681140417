import { errorHandler } from "../../../../services/Miscellaneous/ErrorHandlerServices";
import { setStorageValue } from "../../../../services/Miscellaneous/LocalStorageServices";
import { userLoginServices, userSignUpServices } from "../../../../services/auth/AuthServices";

//#region Login Actions
export const loginAction = (obj) => async (dispatch) => {
  try {
    // Call Service
    let response = await userLoginServices(obj);
    // console.log(response.result.token);
    // setStorageValue("token", response.result.token);
    return response.data.result;
  } catch (err) {
    errorHandler(err);
  }
};
// #endregion


//#region Login Actions
export const signUpAction = (obj) => async (dispatch) => {
  try {
    // Call Service
    let response = await userSignUpServices(obj);
    setStorageValue("token", response.data.result.token);
    return response.data.result;
  } catch (err) {
    errorHandler(err);
  }
};
// #endregion
