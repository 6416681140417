import React from "react";
import backgroundVideo from "../../../assets/images/bannerVideo.mp4"; // Add your video path here
import missionImage from "../../../assets/images/banner2.png"; // Replace with your mission/vision image

function About() {
  return (
    <div>
   
      {/* Fixed Video Background */}
      <div className="fixed top-0 left-0 w-full h-screen -z-10">
        <video
          autoPlay
          loop
          muted
          className="absolute inset-0 w-full h-full object-cover"
        >
          <source src={backgroundVideo} type="video/mp4" />
        </video>
        {/* Skyblue Blur Overlay */}
        <div className="absolute inset-0 bg-white bg-opacity-50 backdrop-blur-md"></div>
      </div>

      {/* Content Section */}
      <div className="relative container mx-auto px-5 py-20 space-y-20">
        {/* Row 1: About Us */}
        <div
          id="about-us"
          className="text-center max-w-4xl mx-auto"
         
        >
          <h1 className="text-5xl md:text-6xl font-bold bg-gradient-to-r from-shuttle to-white bg-clip-text animate-text">
            About Us
          </h1>
          <p className="text-black text-lg md:text-xl mt-5 font-thin">
            BRFARMS, a unit of Black Rock LLC Company, specializes in acquiring
            land and transforming it into luxurious residential plots and
            high-end villas. Located in Dubai, U.A.E., BRFARMS redefines living
            by combining modern design, sustainability, and exclusivity. We
            create opportunities for investors and homeowners to own a slice of
            paradise in prime locations.
          </p>
        </div>

        {/* Row 2: Image and Mission/Vision */}
        <div className="flex flex-col md:flex-row items-center space-y-10 md:space-y-0">
          {/* Left Column: Image */}
          <div
            className="w-full md:w-1/2 flex justify-center"
          
          >
            <img
              src={missionImage}
              alt="Mission and Vision"
              className="w-full md:w-3/4"
            />
          </div>

          {/* Right Column: Mission and Vision */}
          <div className="w-full md:w-1/2 space-y-10">
            {/* Mission */}
            <div
              className="bg-darkgray p-5 "
          
            >
              <h2
                className="text-4xl font-bold bg-gradient-to-r from-shuttle to-white bg-clip-text animate-text"
               
              >
                Mission
              </h2>
              <p className="text-black text-xl md:text-lg mt-5 font-thin">
                Our mission is to revolutionize the real estate market by
                providing luxury residential spaces that reflect modern
                lifestyles and sustainable practices. BRFARMS is committed to
                creating value for clients through world-class construction and
                thoughtfully designed communities that inspire and elevate.
              </p>
            </div>

            {/* Vision */}
            <div
              className="bg-darkgray p-5 "
            
            >
              <h2
                className="text-4xl font-bold bg-gradient-to-r from-shuttle to-white bg-clip-text animate-text"
               
              >
                Vision
              </h2>
              <p className="text-black text-sm md:text-lg mt-5 font-thin">
                Our vision is to set a new standard in the real estate industry
                by creating iconic, eco-friendly residential developments that
                blend luxury and sustainability. BRFARMS aims to be the
                preferred choice for homeowners and investors, delivering
                exceptional experiences and unmatched quality.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
