import React, { useState, useEffect, useRef } from "react";
import Tree from "react-d3-tree";
import { getMemberTreeService } from "../../../services/user/UserServices";
import { getStorageValue } from "../../../services/Miscellaneous/LocalStorageServices";

const MemberTree = () => {
  const [treeData, setTreeData] = useState(null); // State to hold tree data
  const treeContainerRef = useRef();
  const [userDetails, setUserDetails] = useState(); // Store user details

  // Function to fetch tree data for the given ownId
  const fetchTreeData = async (ownId) => {
    try {
      const payload = { ownId }; // Construct payload
      const response = await getMemberTreeService(payload); // Call API
      
      if (response?.result?.success && response?.result?.data) {
        return response.result.data; // Return the tree data
      }
      return null;
    } catch (error) {
      console.error("Error fetching member tree:", error.message);
      return null;
    }
  };

  // Fetch the initial tree data on component mount
  useEffect(() => {
    const storedUser = getStorageValue("userBasicDetail"); // Fetch user details from local storage
    if (storedUser?.ownId) {
      setUserDetails(storedUser); // Set user details
      const fetchInitialTree = async () => {
        const data = await fetchTreeData(storedUser.ownId); // Fetch tree for the user's ownId
        setTreeData(data); // Update state with tree data
      };
      fetchInitialTree();
    }
  }, []);

  // Render custom node content
  const renderNode = ({ nodeDatum }) => (
    <g>
      {/* Add a blurred background circle */}
      <circle r={14} fill="rgba(255, 255, 255, 0.5)" />
      <circle r={10} fill="#007bff" /> {/* Main node circle */}
      <text
        fill="white" // Light text for contrast
        strokeWidth="0.5"
        x={20}
        style={{ cursor: "pointer", fontSize: "12px", fontWeight: "bold" }}
      >
        {nodeDatum.name} ({nodeDatum.ownId})
      </text>
    </g>
  );
  return (
    <div
    style={{
      width: "100%",
      height: "100vh",
      background: "rgba(255, 255, 255, 0.2)", // Light semi-transparent background
      backdropFilter: "blur(10px)", // Blur effect
      WebkitBackdropFilter: "blur(10px)", // Safari support
    }}
    ref={treeContainerRef}
  >
      <h1 className="text-xl font-bold mb-4 bg-white text-center">Member Tree</h1>
      {treeData ? (
       <Tree
       data={treeData}
       orientation="vertical"
       translate={{ x: 200, y: 200 }}
       pathFunc="straight"
       renderCustomNodeElement={renderNode}
       styles={{
         links: {
           stroke: "rgba(255, 255, 255, 0.7)", // Light line color for visibility
           strokeWidth: 2,
         },
         nodes: {
           node: {
             circle: {
               fill: "#007bff", // Node color
               stroke: "rgba(255, 255, 255, 0.8)", // Light border
               strokeWidth: 1.5,
             },
           },
           leafNode: {
             circle: {
               fill: "#28a745", // Leaf node color
               stroke: "rgba(255, 255, 255, 0.8)", // Light border for leaf
               strokeWidth: 1.5,
             },
           },
         },
       }}
     />
     
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default MemberTree;
