import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getRewardListServices, refreshRewardListServices } from '../../../services/admin/AdminServices';

function RewardList() {
  const [rewardList, setRewardList] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch rewards service
  const fetchRewardList = async () => {
    setLoading(true);
    try {
      const response = await getRewardListServices() // Adjust the API endpoint
      setRewardList(response.result.data);
    } catch (error) {
      console.error('Error fetching reward list:', error);
    } finally {
      setLoading(false);
    }
  };

  // Refresh rewards service
  const refreshRewardList = async () => {
    setLoading(true);
    try {
      await refreshRewardListServices() // Adjust the API endpoint
      fetchRewardList(); // Fetch updated list after refresh
    } catch (error) {
      console.error('Error refreshing reward list:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRewardList(); // Fetch rewards on component mount
  }, []);

  return (
    <div className="overflow-auto  bg-gray-100 p-6">
      <div className="container mx-auto">
        <h1 className="text-2xl font-bold text-gray-800 mb-4">Reward List</h1>
        <button
          onClick={refreshRewardList}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
        >
          {loading ? 'Refreshing...' : 'Refresh'}
        </button>
        <div className="mt-4 bg-white rounded shadow overflow-hidden">
  <div className="overflow-y-auto max-h-96">
    <table className="min-w-full table-auto">
      <thead className="bg-gray-200 sticky top-0 z-10">
        <tr>
          <th className="px-4 py-2 text-left text-gray-600">Sr.no</th>
          <th className="px-4 py-2 text-left text-gray-600">Name</th>
          <th className="px-4 py-2 text-left text-gray-600">Mobile</th>
          <th className="px-4 py-2 text-left text-gray-600">Email</th>
          <th className="px-4 py-2 text-left text-gray-600">Level achieved</th>
          <th className="px-4 py-2 text-left text-gray-600">Reward</th>
          <th className="px-4 py-2 text-left text-gray-600">Units</th>
          <th className="px-4 py-2 text-left text-gray-600">Children</th>
          <th className="px-4 py-2 text-left text-gray-600">Last Checked</th>
        </tr>
      </thead>
      <tbody>
        {rewardList.length > 0 ? (
          rewardList.map((reward, index) => (
            <tr key={reward._id} className="border-b hover:bg-gray-50">
              <td className="px-4 py-2">{index + 1}</td>
              <td className="px-4 py-2">{reward.name}</td>
              <td className="px-4 py-2">{reward.mobile}</td>
              <td className="px-4 py-2">
                {reward.email ? `${reward.email.slice(0, 4)}.....` : 'N/A'}
              </td>
              <td className="px-4 py-2">Level {reward.achievedLevel}</td>
              <td className="px-4 py-2">{reward.reward}</td>
              <td className="px-4 py-2">{reward.units}</td>
              <td className="px-4 py-2">{reward.children}</td>
              <td className="px-4 py-2">{new Date(reward.lastCheckedDate).toLocaleString()}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="9" className="px-4 py-2 text-center text-gray-500">
              No rewards found.
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
</div>

      </div>
    </div>
  );
}

export default RewardList;
