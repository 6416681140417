import React, { useState, useEffect } from "react";
import { FaEdit } from "react-icons/fa"; // Edit icon
import {
  updateProfileServices,
  uploadProfilePictureServices,
  updateProfilePictureServices,
  getUserDetailService,
} from "../../../services/user/UserServices"; // Import your services
import axios from "axios";
import { errorHandler } from "../../../services/Miscellaneous/ErrorHandlerServices";
import { BASE_FILE_PATH } from "../../../utils/Urls";

function Profile() {
  const [userData, setUserData] = useState({});
  const [editable, setEditable] = useState({});
  const [profileImage, setProfileImage] = useState(
    "https://cdn.vectorstock.com/i/2000v/51/87/student-avatar-user-profile-icon-vector-47025187.avif"
  );
  const [selectedTab, setSelectedTab] = useState("general");
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await getUserDetailService();

      const fetchedData = response.result;
      setUserData(fetchedData);
      setProfileImage(
        `${BASE_FILE_PATH}/files/${fetchedData.image}` ||
          "https://cdn.vectorstock.com/i/2000v/51/87/student-avatar-user-profile-icon-vector-47025187.avif"
      );
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const ACCOUNT_FIELDS = [
    {
      label: "Aadhaar Number",
      field: "AdharNumber",
      validation: (value) => /^\d{12}$/.test(value) || "Aadhaar Number must be 12 digits.",
    },
    {
      label: "PAN Number",
      field: "panNumber",
      validation: (value) =>
        /^[A-Z]{5}\d{4}[A-Z]$/.test(value) ||
        "PAN Number must have 5 uppercase letters, 4 digits, and 1 uppercase letter.",
    },
    {
      label: "Account Number",
      field: "accountNumber",
      validation: (value) =>
        /^\d{10,18}$/.test(value) || "Account Number must be numeric and 10 to 18 digits long.",
    },
    {
      label: "Bank Name",
      field: "bankName",
      validation: (value) =>
        /^[A-Za-z ]+$/.test(value) || "Bank Name must contain only alphabets and spaces.",
    },
    {
      label: "IFSC Code",
      field: "bankIfscCode",
      validation: (value) =>
        /^[A-Z]{4}0[A-Z0-9]{6}$/.test(value) || "IFSC Code must be 11 characters long with valid format.",
    },
    {
      label: "UPI ID",
      field: "UpiId",
      validation: (value) =>
        /^[a-zA-Z0-9.\-]+@[a-zA-Z0-9]+$/.test(value) || "UPI ID must contain alphanumeric characters, a '@', and no spaces.",
    },
    {
      label: "USDT BEP-20 | Wallet Address",
      field: "walletAddress",
      validation: (value) =>
        /^0x[a-fA-F0-9]{40}$/.test(value) ||
        "Wallet Address must start with '0x' and be 42 alphanumeric characters long.",
    },
  ];
  
  

const handleSave = async (field, newValue) => {
  try {
    const payload = { [field]: newValue }; // Use field name for payload
    await updateProfileServices(payload);

    setUserData((prev) => ({
      ...prev,
      [field]: newValue,
    }));

    setEditable((prev) => ({
      ...prev,
      [field]: false,
    }));

    alert(`${field} updated successfully!`);
  } catch (error) {
    console.error("Error updating field:", error);
    alert("Failed to update field. Please try again.");
  }
};

  

  const handleEditToggle = (field) => {
    // Allow editing only if the field is in the Account Tab and currently empty
    if (ACCOUNT_FIELDS.includes(field) && !userData[field]) {
      setEditable((prev) => ({ ...prev, [field]: true }));
    } else if (!ACCOUNT_FIELDS.includes(field)) {
      // For General Tab fields, allow editing as usual
      setEditable((prev) => ({ ...prev, [field]: true }));
    }
  };

  const handleInputChange = (field, value) => {
    setUserData((prev) => ({
      ...prev,
      [field]: value, // Update the specific field
    }));
  };
  

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsLoading(true);
      try {
        const uploadResponse = await uploadProfilePictureServices(file);
        let picturePath = uploadResponse.path.split("/files/")[1];
        let payload = {
          userId: userData._id,
          imagePath: picturePath,
        };
        const profileUpload = await updateProfilePictureServices(payload);
        if (profileUpload.msg === "Success") {
          alert("Profile picture updated successfully!");
        } else {
          alert("issue in upload picture");
        }
      } catch (error) {
        errorHandler(error);
      }
      setIsLoading(false);
    }
  };

  const tabs = [
    { label: "General", key: "general" },
    { label: "Account", key: "account" },
  ];

  return (
    <div className="min-h-screen flex justify-center items-center text-black">
      <div className="bg-gradient-to-l from-transparent to-blue-900 rounded-2xl border-[0.5px] shadow-xl w-full max-w-4xl overflow-hidden">
        <div className="bg-gradient-to-l from-transparent to-blue-900 rounded-2xl border-[0.5px] shadow-xl relative p-6 flex flex-col justify-space md:flex-row items-center md:items-start space-y-4 md:space-y-0 md:space-x-6 justify-evenly">
          {/* Profile Image */}
          <div className="relative w-32 h-32 md:w-40 md:h-40 overflow-hidden flex-shrink-0">
            <img
              src={profileImage} // Add fallback for the default image
              alt="Profile"
              className="w-full h-full rounded-full object-cover"
            />
            <label
              htmlFor="image-upload"
              className="absolute bottom-0 right-0 text-black bg-white rounded-2xl p-1 cursor-pointer"
            >
              <FaEdit className="w-5 h-5" />
            </label>
            <input
              id="image-upload" // Ensure this matches the `htmlFor` of the label
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className="hidden" // Keep the input hidden; interaction is via label
              name="image"
            />
          </div>

          {/* Client Information */}
          <div className="flex flex-col justify-center items-center md:items-start text-white space-y-2 w-full md:w-auto">
            <h2 className="text-2xl md:text-3xl font-bold text-center md:text-left">
              {userData.name || "Your Name"}
            </h2>
            <p className="text-lg md:text-xl font-semibold text-gray-300 text-center md:text-left">
              Client ID:{" "}
              <span className="text-white font-bold">
                {userData.ownId || "N/A"}
              </span>
            </p>
            <p className="text-sm md:text-base text-gray-300">
              <span className="font-semibold">Referred By:</span>{" "}
              {userData.referredBy || "N/A"}
            </p>
            <p className="text-sm md:text-base text-gray-300">
              <span className="font-semibold">Parent ID:</span>{" "}
              {userData.parentId || "N/A"}
            </p>
          </div>
        </div>

        <div className="flex text-black justify-around bg-gray-300 p-3">
          {tabs.map((tab) => (
            <button
              key={tab.key}
              className={`text-sm font-medium px-4 py-2 rounded-lg ${
                selectedTab === tab.key
                  ? "bg-blue-500 text-white"
                  : "bg-white text-gray-600"
              }`}
              onClick={() => setSelectedTab(tab.key)}
            >
              {tab.label}
            </button>
          ))}
        </div>

        <div className="p-6 space-y-4 text-black overflow-y-auto max-h-96">
          {selectedTab === "general" && (
            <>
              <ProfileField
                label="Name"
                field="name"
                value={userData.name}
                editable={editable.name}
                onEdit={() => handleEditToggle("name")}
                onChange={(value) => handleInputChange("name", value)}
                onSave={() => handleSave("name")}
              />
              <ProfileField
                label="Email"
                field="email"
                value={userData.email}
                editable={false}
              />
              <ProfileField
                label="Mobile"
                field="mobile"
                value={userData.mobile}
                editable={false}
              />
              <ProfileField
                label="Date of Birth"
                field="dob"
                value={userData.dob}
                editable={editable.dob}
                onEdit={() => handleEditToggle("dob")}
                onChange={(value) => handleInputChange("dob", value)}
                onSave={() => handleSave("dob")}
              />

              <ProfileField
                label="Address"
                field="address"
                value={userData.address}
                editable={editable.address}
                onEdit={() => handleEditToggle("address")}
                onChange={(value) => handleInputChange("address", value)}
                onSave={() => handleSave("address")}
              />
            </>
          )}

{selectedTab === "account" && (
  <>
    {ACCOUNT_FIELDS.map(({ label, field, validation }) => (
      <AccountField
        key={field}
        label={label}
        field={field}
        value={userData[field]}
        editable={editable[field] || !userData[field]}
        onEdit={() => handleEditToggle(field)}
        onChange={(value) => handleInputChange(field, value)}
        onSave={(value) => handleSave(field, value)}
        validation={validation} // Pass validation function
      />
    ))}
  </>
)}



        </div>
      </div>
    </div>
  );
}

const ProfileField = ({
  label,
  field,
  value,
  editable,
  onEdit,
  onChange,
  onSave,
}) => {
  const formattedValue =
    field === "dob" && value
      ? new Date(value).toISOString().split("T")[0]
      : value || "";

  return (
    <div className="flex items-center justify-between space-x-4">
      <div className="w-1/4 font-semibold text-white">{label}</div>
      <div className="w-3/4 flex items-center space-x-2">
        <input
          type={field === "dob" ? "date" : "text"}
          value={formattedValue}
          onChange={(e) => onChange(e.target.value)}
          disabled={!editable}
          className={`w-full border rounded-lg p-2 ${
            editable
              ? "border-blue-500"
              : "border-gray-300 bg-gray-200 cursor-not-allowed"
          }`}
        />
        {editable ? (
          <button
            onClick={onSave}
            className="bg-blue-500 text-white rounded-lg px-3 py-1"
          >
            Save
          </button>
        ) : (
          !value && (
            <button onClick={onEdit} className="text-blue-500">
              <FaEdit />
            </button>
          )
        )}
      </div>
    </div>
  );
};

const AccountField = ({
  label,
  field,
  value,
  editable,
  onEdit,
  onChange,
  onSave,
  validation,
}) => {
  const [inputValue, setInputValue] = useState(value || "");
  const [error, setError] = useState("");

  useEffect(() => {
    setInputValue(value || ""); // Sync input value with props
  }, [value]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setError(""); // Clear previous error on input change
  };

  const handleSave = () => {
    const validationMessage = validation(inputValue);
    if (validationMessage === true) {
      onSave(inputValue); // Only save if validation passes
    } else {
      setError(validationMessage); // Display validation error
    }
  };

  return (
    <div className="flex flex-col space-y-1">
      <label className="font-semibold text-white">{label}</label>
      <div className="flex items-center space-x-2">
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          disabled={!editable}
          className={`w-full border rounded-lg p-2 ${
            editable
              ? "border-blue-500"
              : "border-gray-300 bg-gray-200 cursor-not-allowed"
          }`}
        />
        {editable ? (
          <button
            onClick={handleSave}
            className="bg-blue-500 text-white rounded-lg px-3 py-1"
          >
            Save
          </button>
        ) : (
          !value && (
            <button onClick={onEdit} className="text-blue-500">
              <FaEdit />
            </button>
          )
        )}
      </div>
      {error && <span className="text-red-500 text-sm">{error}</span>}
    </div>
  );
};





export default Profile;
