// User Auth
import axios from "../../utils/Axios";
import { BASE_URL } from "../../utils/Urls";

export const userLoginServices = async (data) => {
  let url = `${BASE_URL}auth/login`;
  let response= await axios.post(url, data);
  console.log(response,'res');
  return response
};

export const userSignUpServices = async (data) => {
  let url = `${BASE_URL}auth/signup`;
  return await axios.post(url, data);
};

export const adminLoginServices = async (data) =>
  await axios.post(`${BASE_URL}admin/login`, data);

export const adminSignUpServices = async (data) =>
  await axios.post(`${BASE_URL}admin/signup`, data);


  export const getOtpServices = async (data) =>
  await axios.post(`${BASE_URL}auth/otpsignup`, data);

   export const getRewardlistservice = async () =>
  await axios.get(`${BASE_URL}auth/rewardlist`);

  export const verifyOtpServices = async (data) =>
  await axios.post(`${BASE_URL}auth/otpvalidate`, data);
  
  export const getOtpForForgetPasswordServices = async (data) =>
  await axios.post(`${BASE_URL}user/generateOtpForPasscodeReset`, data);
  
  export const resetPasscodeServices = async (data) =>
  await axios.put(`${BASE_URL}user/resetPasscode`, data);