import React, { useState, useEffect, useRef } from "react";
import Tree from "react-d3-tree";
import { getUserTreeService } from "../../../services/admin/AdminServices";

const UserTree = () => {
  const [treeData, setTreeData] = useState(null); // State to hold tree data
  const [expandedNodes, setExpandedNodes] = useState({}); // Track expanded nodes
  const treeContainerRef = useRef();

  // Function to fetch user tree data
  const fetchTreeData = async (parentId = null) => {
    try {
      const response = await getUserTreeService(parentId);
      if (response?.result?.data?.length > 0) {
        return response.result.data[0]; // Assuming the first node is the root
      }
      return null;
    } catch (error) {
      console.error("Error fetching user tree:", error);
      return null;
    }
  };

  // Dynamically load children when expanding a node
  const expandNode = async (nodeData) => {
    if (expandedNodes[nodeData._id]) return; // Skip if already expanded
    const childData = await fetchTreeData(nodeData.ownId); // Fetch children
    if (childData && childData.children?.length > 0) {
      updateTreeWithChildren(treeData, nodeData._id, childData.children);
      setExpandedNodes((prev) => ({ ...prev, [nodeData._id]: true }));
    }
  };

  // Recursive function to update tree data with new children
  const updateTreeWithChildren = (tree, nodeId, children) => {
    if (tree._id === nodeId) {
      tree.children = children;
    } else if (tree.children?.length > 0) {
      tree.children.forEach((child) =>
        updateTreeWithChildren(child, nodeId, children)
      );
    }
    setTreeData({ ...tree }); // Update state
  };

  // Initial fetch on component mount
  useEffect(() => {
    const fetchInitialTree = async () => {
      const data = await fetchTreeData();
      setTreeData(data);
    };
    fetchInitialTree();
  }, []);

  // Render custom node content
  const renderNode = ({ nodeDatum, toggleNode }) => (
    <g>
      <circle r={10} onClick={() => toggleNode(nodeDatum)} fill="#007bff" />
      <text
        fill="black"
        strokeWidth="0.5"
        x={20}
        onClick={() => expandNode(nodeDatum)}
        style={{ cursor: "pointer" }}
      >
        {nodeDatum.name} ({nodeDatum.ownId})
      </text>
    </g>
  );

  return (
    <div style={{ width: "100%", height: "100vh" }} ref={treeContainerRef}>
      <h1 className="text-xl font-bold mb-4">User Tree</h1>
      {treeData ? (
        <Tree
          data={treeData}
          orientation="vertical"
          translate={{ x: 200, y: 200 }}
          pathFunc="straight"
          renderCustomNodeElement={renderNode} // Custom node rendering
        />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default UserTree;
