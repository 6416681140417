import React, { useEffect, useState } from "react";
import { getAllTransaction } from "../../../services/admin/AdminServices";
import { errorHandler } from "../../../services/Miscellaneous/ErrorHandlerServices";
import { getDateAndTimeByTimestamp } from "../../../utils/DateAndTimeFunctions";

const Transactions = ({ adminActions, adminActionsState }) => {
  const [transactionList, setTransactionList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  const transactionListHandler = async () => {
    try {
      let response = await getAllTransaction();
      setTransactionList(response.result);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    transactionListHandler();
  }, []);

  // Pagination logic
  const totalPages = Math.ceil(transactionList.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = transactionList.slice(startIndex, startIndex + itemsPerPage);

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <div className="flex justify-end"></div>
      <div className="border-2 border-black my-4"></div>

      <div className="relative overflow-x-auto h-[80vh]">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">Sr No</th>
              <th scope="col" className="px-6 py-3">Date</th>
              <th scope="col" className="px-6 py-3">Member ID</th>
              <th scope="col" className="px-6 py-3">Email</th>
              <th scope="col" className="px-6 py-3">Type</th>
              <th scope="col" className="px-6 py-3">Amount</th>
              <th scope="col" className="px-6 py-3">Narration</th>
              <th scope="col" className="px-6 py-3">Transaction ID</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((ele, i) => (
              <tr key={i} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {startIndex + i + 1}
                </th>
                <td className="px-6 py-4">
                  {getDateAndTimeByTimestamp(ele?.transactionDate)}
                </td>
                <td className="px-6 py-4">{ele?.memberId}</td>
                <td className="px-6 py-4">{ele?.email}</td>
                <td className="px-6 py-4">{ele?.type}</td>
                <td className="px-6 py-4">{ele?.amount}</td>
                <td className="px-6 py-4">{ele?.narration}</td>
                <td className="px-6 py-4">{ele?.transactionHash}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      {transactionList.length > itemsPerPage && (
        <nav className="flex items-center justify-between py-4 text-gray-700 dark:text-white">
          <button
            onClick={handlePrevious}
            disabled={currentPage === 1}
            className={`px-4 py-2 rounded-lg ${
              currentPage === 1 ? "bg-gray-300 cursor-not-allowed" : "bg-blue-500 text-white"
            }`}
          >
            Previous
          </button>
          <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm">
            {Array.from({ length: totalPages }, (_, index) => (
              <li key={index}>
                <button
                  onClick={() => handlePageClick(index + 1)}
                  className={`px-3 h-8 leading-tight border ${
                    currentPage === index + 1
                      ? "bg-blue-500 text-white"
                      : "bg-white text-gray-700 hover:bg-gray-100"
                  }`}
                >
                  {index + 1}
                </button>
              </li>
            ))}
          </ul>
          <button
            onClick={handleNext}
            disabled={currentPage === totalPages}
            className={`px-4 py-2 rounded-lg ${
              currentPage === totalPages ? "bg-gray-300 cursor-not-allowed" : "bg-blue-500 text-white"
            }`}
          >
            Next
          </button>
        </nav>
      )}
    </div>
  );
};

export default Transactions;