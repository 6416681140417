import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

function Gallery() {
  // Array of image objects for react-image-gallery
  const images = Array.from({ length: 24 }, (_, i) => ({
    original: require(`../../../assets/images/homes/home${String(i + 1).padStart(2, "0")}.jpg`),
    thumbnail: require(`../../../assets/images/homes/home${String(i + 1).padStart(2, "0")}.jpg`),
  }));

  return (
    <div className="container mx-auto px-6 py-12">
      <h2 className="text-4xl font-bold text-black mb-6 text-center">Gallery</h2>
      <div className="gallery-container">
        <ImageGallery
          items={images}
          showThumbnails={true}
          showFullscreenButton={true}
          showPlayButton={true}
          lazyLoad={true}
          slideDuration={500}
          additionalClass="custom-gallery"
        />
      </div>
    </div>
  );
}

export default Gallery;
