import React, { useEffect, useState, useRef } from "react";
import {
  FaCopy,
  FaShare,
} from "react-icons/fa";
import {
  TwitterShareButton,
  FacebookShareButton,
  TelegramShareButton,
  TwitterIcon,
  TelegramIcon,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { getStorageValue } from "../../services/Miscellaneous/LocalStorageServices";

const ShareButtons = ({ url, title }) => {
  return (
    <div>
      <TwitterShareButton url={url} title={title}>
        <TwitterIcon className="mx-3" size={32} round />
      </TwitterShareButton>
    <TelegramShareButton url={url} title={title}>
      <TelegramIcon className="mx-3" size={32} round />
    </TelegramShareButton>
      <FacebookShareButton url={url} quote={title}>
        <FacebookIcon className="mx-3" size={32} round />
      </FacebookShareButton>

      <WhatsappShareButton url={url} title={title}>
        <WhatsappIcon className="mx-3" size={32} round />
      </WhatsappShareButton>
    </div>
  );
};

const RSSUsage = () => {
  const [referralLink, setReferralLink] = useState("");
  const shareData = {
    url: referralLink,
    title: "Your awesome content",
  };

  useEffect(() => {
    // Retrieve user details from localStorage
    const userBasicDetails =  getStorageValue("userBasicDetail");
    console.log(userBasicDetails)
    const ownId = userBasicDetails?.ownId || "defaultId"; // Fallback to a default value
    const baseUrl = "https://brfarm.in/signup";
    setReferralLink(`${baseUrl}?referredBy=${ownId}`);
  }, []);

  const copyToClipboard = (url) => {
    const textArea = document.createElement("textarea");
    textArea.value = url;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    console.log("URL copied to clipboard:", url);
  };

  return (
    <div className="h-screen overflow-hidden flex scrollable-content mt-28 justify-center">
      <div className="h-96 w-96 bg-white/20 rounded-2xl justify-center flex items-center flex-col">
        <p className="text-theme font-semibold text-2xl text-center">
          Share On Social Platforms:
        </p>
        <div className="mt-7">
          <ShareButtons {...shareData} />
        </div>
        <p className="text-muted text-white mx-10 my-5">
          Share your personal ID so that it can be entered as the referral ID,
          allowing them to join your team.
        </p>
        <div className="bg-white justify-around w-[90%] mt-4 p-3 flex rounded-lg items-center">
          <p className="text-black text-sm">{referralLink}</p>
          <button onClick={() => copyToClipboard(referralLink)}>
            <FaCopy />
          </button>
        </div>
      </div>
    </div>
  );
};

export default RSSUsage;
