import React, { useEffect, useState } from "react";
import { errorHandler } from "../../../services/Miscellaneous/ErrorHandlerServices";
import { getLevelWiseIncomeServices } from "../../../services/user/UserServices";
import { getDateAndTimeByTimestamp } from "../../../utils/DateAndTimeFunctions";

function LevelIncome() {
  const [totalIncomeList, setTotalIncomeList] = useState([]);
  const [currentLevel, setCurrentLevel] = useState("ALL"); // Default value
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items per page
  const levels = Array.from({ length: 15 }, (_, i) => i + 1); // Generate levels 1 to 15

  const refferralsList = async () => {
    try {
      let response = await getLevelWiseIncomeServices(currentLevel);
      setTotalIncomeList(response.result);
      setCurrentPage(1); // Reset to the first page when level changes
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    refferralsList();
  }, [currentLevel]);

  // Pagination logic
  const totalPages = Math.ceil(totalIncomeList.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = totalIncomeList.slice(startIndex, startIndex + itemsPerPage);

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="h-screen overflow-hidden">
      {/* Dropdown for Level Selection */}
      <div className="pb-4 dark:bg-gray-900 ml-5">
        <div className="relative mt-1">
          <button
            id="dropdownDefaultButton"
            data-dropdown-toggle="dropdown"
            className="text-white flex justify-between w-[180px] bg-gradient-to-r from-transparent to-blue-900 flex items-center p-3 border border-blue-950 shadow-lg rounded-xl"
            type="button"
            onClick={() => {
              document.getElementById("dropdown").classList.toggle("hidden");
            }}
          >
            Level {currentLevel}
            <svg
              className="w-2.5 h-2.5 ms-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 4 4 4-4"
              />
            </svg>
          </button>

          <div
            id="dropdown"
            className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
          >
            <ul
              className="py-2 text-sm text-gray-700 dark:text-gray-200 h-[300px] overflow-scroll"
              aria-labelledby="dropdownDefaultButton"
            >
              <li className="text-center flex justify-center">
                <button
                  onClick={() => {
                    setCurrentLevel("ALL");
                    document.getElementById("dropdown").classList.add("hidden");
                  }}
                  className="block px-4 py-2 text-center hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  ALL
                </button>
              </li>
              {levels.map((level) => (
                <li key={level} className="text-center flex justify-center">
                  <button
                    onClick={() => {
                      setCurrentLevel(level);
                      document.getElementById("dropdown").classList.add("hidden");
                    }}
                    className="block px-4 py-2 text-center hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    Level {level}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      {/* Table with Pagination */}
      <div className="grid lg:grid-cols-1 grid-cols-1 px-4 py-5 m-4 bg-gradient-to-l from-transparent to-blue-900 rounded-2xl border-[0.5px] border-white/10 shadow-xl">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-white uppercase bg-userGradiant">
              <tr>
                <th scope="col" className="p-4">Sl</th>
                <th scope="col" className="px-6 py-3">Member Id</th>
                <th scope="col" className="px-6 py-3">Plan Amount</th>
                <th scope="col" className="px-6 py-3">Commission</th>
                <th scope="col" className="px-6 py-3">Level</th>
                <th scope="col" className="px-6 py-3">Commission Type</th>
                <th scope="col" className="px-6 py-3">Date</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.length > 0 ? (
                currentItems.map((ele, ind) => (
                  <tr key={ind} className="border-b hover:bg-blue-900">
                    <td className="w-4 p-4">{startIndex + ind + 1}</td>
                    <td className="px-6 py-4 text-white">{ele?.newMemberReferralCode || "N/A"}</td>
                    <td className="px-6 py-4 text-white">{ele?.totalAmount || "N/A"}</td>
                    <td className="px-6 py-4 text-white">{ele?.commisionAmount || "N/A"}</td>
                    <td className="px-6 py-4 text-white">{ele?.commisionLevel || "N/A"}</td>
                    <td className="px-6 py-4 text-white">For New Joining</td>
                    <td className="px-6 py-4 text-white">{getDateAndTimeByTimestamp(ele?.timeStamp)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="7"
                    className="px-6 py-4 text-center text-white font-medium"
                  >
                    No data to show
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Pagination Controls */}
          {totalIncomeList.length > itemsPerPage && (
            <nav className="flex items-center justify-between py-4 text-white">
              <button
                onClick={handlePrevious}
                disabled={currentPage === 1}
                className={`px-4 py-2 rounded-lg ${
                  currentPage === 1 ? "bg-gray-500 cursor-not-allowed" : "bg-blue-600"
                }`}
              >
                Previous
              </button>
              <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm">
                {Array.from({ length: totalPages }, (_, index) => (
                  <li key={index}>
                    <button
                      onClick={() => handlePageClick(index + 1)}
                      className={`px-3 h-8 leading-tight border ${
                        currentPage === index + 1
                          ? "bg-blue-500 text-white"
                          : "bg-white text-gray-500 hover:bg-gray-100 hover:text-gray-700"
                      }`}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
              </ul>
              <button
                onClick={handleNext}
                disabled={currentPage === totalPages}
                className={`px-4 py-2 rounded-lg ${
                  currentPage === totalPages ? "bg-gray-500 cursor-not-allowed" : "bg-blue-600"
                }`}
              >
                Next
              </button>
            </nav>
          )}
        </div>
      </div>
    </div>
  );
}

export default LevelIncome;