import axios from "../../utils/Axios";
import { BASE_URL } from "../../utils/Urls";


export const adminDashBoardServices = async () => {
  let url = `${BASE_URL}admin/getDashboardDetails`;
  return await axios.get(url);
};

export const createUserServices = async (data) => {
  let url = `${BASE_URL}admin/createDirectUser`;
  return await axios.post(url, data);
};

export const verifyUser = async (data) => {
  let url = `${BASE_URL}admin/verifyid`;
  return await axios.post(url, data);
};

export const userListServices = async () => {
  let url = `${BASE_URL}admin/getAllUserWithWalletBalance`;
  return await axios.get(url);
};
export const activationRequestServices = async () => {
  let url = `${BASE_URL}admin/activation-requests`;
  return await axios.get(url);
};

export const getAllTransaction = async () => {
  let url = `${BASE_URL}admin/getAllTransaction`;
  return await axios.get(url);
};

export const getAllWithdrawalRequest = async () => {
  let url = `${BASE_URL}admin/getAllWithdrawalRequest`;
  return await axios.get(url);
};
export const updateWithdrawalRequest = async (payload) => {
  let url = `${BASE_URL}admin/withdrawals/update`;
  console.log("payload", payload)
  return await axios.put(url,payload);
};

export const getUserTreeService = async () => {
  let url = `${BASE_URL}admin/users-tree`;
  return await axios.post(url);
};

export const bussinessReportListServices = async () => {
  let url = `${BASE_URL}admin/getbusinessreport`;
  return await axios.get(url);
};
export const getRewardListServices = async () => {
  let url = `${BASE_URL}admin/rewardsist`;
  return await axios.get(url);
};
export const refreshRewardListServices = async () => {
  let url = `${BASE_URL}admin/checkrewardalluser`;
  return await axios.get(url);
};

export const activationRequestUpdateService = async (id, updates) => {
  let url = `${BASE_URL}activation-request/${id}`;
  return await axios.put(url, updates);
};

export const bussinesReportByType = async (type) => {
  let url = `${BASE_URL}admin/getbusinessreport?type=${type}`;
  return await axios.get(url);
};

export const bussinessDetailsBySpecificService = async (date) => {
  let url = `${BASE_URL}admin/getbusinessofspecificdate/${date}`;
  return await axios.get(url);
};

export const createActiveUser = async (data) => {
  console.log(data);
  let url = `${BASE_URL}admin/getUserDetailsWithWalletAmount`;
  return await axios.post(url, data);
};

export const activateUserServices = async (data) => {
  
  let url = `${BASE_URL}admin/activateUser`;
  return await axios.post(url, data);
};

export const depositUserDetails = async (data) => {
  console.log(data);
  let url = `${BASE_URL}admin/getUserDetailsWithWalletAmount`;
  return await axios.post(url, data);
};

export const depositUserServices = async (data) => {
  console.log(data);
  let url = `${BASE_URL}admin/depositAmountInWallet`;
  return await axios.post(url, data);
};

export const withdrawUserDetails = async (data) => {
  console.log(data);
  let url = `${BASE_URL}admin/getUserDetailsWithWalletAmount`;
  return await axios.post(url, data);
};
export const withdrawUserServices = async (data) => {
  console.log(data);
  let url = `${BASE_URL}admin/depositAmountInWallet`;
  return await axios.post(url, data);
};

export const resetUserPassword = async (data) => {
  let url = `${BASE_URL}admin/resetPasscode`;
  return await axios.post(url, data);
};

export const changeUserEmail = async (data) => {
  let url = `${BASE_URL}admin/changeEmail`;
  return await axios.post(url, data);
};

export const blockUserServices = async (data) => {
  console.log(data);
  let url = `${BASE_URL}admin/blockUser`;
  return await axios.post(url, data);
};
