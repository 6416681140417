import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import {
  createUserServices,
  verifyUser,
} from "../../../services/admin/AdminServices";
import { toast } from "react-toastify";
import { adminActions } from "../../../redux/action/adminAction/AdminActions/AdminActions";

const RegisterNewUser = ({ adminActions, adminActionsState }) => {
  useEffect(() => {
    adminActions();
  }, []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [referralDetails, setReferralDetails] = useState(null);

  const rowsPerPage = 20;
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isReferralVerified, setIsReferralVerified] = useState(false);

  // Calculate the total pages
  const totalRows = adminActionsState?.userList?.length || 0;
  const totalPages = Math.ceil(totalRows / rowsPerPage);

  // Get the rows for the current page
  const startIndex = (currentPage - 1) * rowsPerPage;
  const currentRows = adminActionsState?.userList?.slice(
    startIndex,
    startIndex + rowsPerPage
  );
  const modalRef = useRef(null);
  // Handle navigation
  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrev = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    mobile: "",
    referredBy: "",
    currency: "",
    selectedPlan: "",
    joiningAmount: 0,
    password: "",
    transactionId: "",
  });
  const [errors, setErrors] = useState({});

  // const validateInputs = () => {
  //   const validationErrors = {};
  //   if (!userDetails.name.trim()) validationErrors.name = "Name is required.";
  //   if (!userDetails.email.trim())
  //     validationErrors.email = "Email is required.";
  //   if (!/^\d{10}$/.test(userDetails.mobile))
  //     validationErrors.mobile = "Enter a valid 10-digit mobile number.";
  //   if (!userDetails.unit)
  //     validationErrors.unit = "Please select a unit to calculate amount.";
  //   return validationErrors;
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Handle validations
    if (name === "mobile" && (!/^\d*$/.test(value) || value.length > 10)) {
      return;
    }
    setUserDetails((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleUnitChange = (e) => {
    const unitValue = e.target.value;
    const updatedJoiningAmount = unitValue ? unitValue * 15000 : 0;
    setUserDetails((prev) => ({
      ...prev,
      unit: unitValue,
      joiningAmount: updatedJoiningAmount,
    }));
    setErrors((prev) => ({ ...prev, unit: null })); // Clear validation error
  };

  const statusMappings = {
    0: { className: "inactive", text: "Inactive" },
    1: { className: "actives", text: "Active" },
    2: { className: "pending", text: "Pending" },
    3: { className: "renewed", text: "Renewed" },
    4: { className: "blocked", text: "Blocked" },
    5: { className: "expired", text: "Expired" },
  };

  const handleVerifyReferral = async () => {
    try {
      setIsLoading(true); // Optional: Show loader if needed
      // Prepare the payload with ownId
      const payload = { ownId: userDetails.referredBy };

      const response = await verifyUser(payload); // Pass the payload to the service

      if (response?.status) {
        toast.success("Referral code found!");
        setIsReferralVerified(true); // Disable input after successful verification
        setReferralDetails(response)
      } else {
        toast.error(response?.message || "Invalid referral code.");
        setIsReferralVerified(false); // Allow user to re-enter the code
      }
    } catch (error) {
      toast.error(error?.message || "An error occurred while verifying.");
      setIsReferralVerified(false);
    } finally {
      setIsLoading(false); // Hide loader if it was shown
    }
  };

  const onSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      const response = await createUserServices(userDetails);
      if (response) {
        toast.success("User Added!");
      } else {
        toast.error("Error");
      }
      modalRef.current?.close();
    } catch (error) {
      toast.error(error.message);
      modalRef.current?.close();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="flex justify-end">
        <button
          onClick={() => setIsModalOpen(true)} // Open the modal
          class="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800"
        >
          <span class="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
            + Add New User
          </span>
        </button>
      </div>
      <div className="border-2 border-black my-4"></div>

      <div>
        {/* Scrollable Table */}
        <div className="relative overflow-x-auto max-h-[650px] overflow-y-auto border rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead
              className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
              style={{
                position: "sticky",
                top: 0,
                zIndex: 10,
              }}
            >
              <tr>
                <th scope="col" className="px-6 py-3">
                  Sr.no.
                </th>
                <th scope="col" className="px-6 py-3 text-center">
                  Name
                </th>
                <th scope="col" className="px-6 py-3 text-center">
                  Email
                </th>
                <th scope="col" className="px-6 py-3 text-center">
                  Mobile Number
                </th>
                <th scope="col" className="px-6 py-3 text-center">
                  Client ID
                </th>
                <th scope="col" className="px-6 py-3 text-center">
                  Parent Id
                </th>
                <th scope="col" className="px-6 py-3 text-center">
                  Referred By
                </th>

                <th scope="col" className="px-6 py-3 text-center">
                  Selected Plan
                </th>
                <th scope="col" className="px-6 py-3 text-center">
                  Status
                </th>
                <th scope="col" className="px-6 py-3 text-center">
                  Wallet Balance
                </th>
              </tr>
            </thead>
            <tbody>
              {currentRows?.map((ele, i) => (
                <tr
                  key={ele?.id || i}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {startIndex + i + 1}
                  </th>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center"
                  >
                    {ele?.name}
                  </th>
                  <td className="px-6 py-4 text-center">{ele?.email}</td>
                  <td className="px-6 py-4 text-center">{ele?.mobile}</td>
                  <td className="px-6 py-4 text-center">{ele?.ownId}</td>
                  <td className="px-6 py-4 text-center">{ele?.parentId}</td>
                  <td className="px-6 py-4 text-center">{ele?.referredBy}</td>
                  <td className="px-6 py-4 text-center">{ele?.selectedPlan}</td>
                  <td className="px-6 py-4 text-center">
                    <div
                      className={
                        statusMappings[ele.status]?.className || "default-class"
                      }
                    >
                      {statusMappings[ele.status]?.text || "Default Status"}
                    </div>
                  </td>
                  <td className="px-6 py-4 text-center">
                    {ele?.walletBalance?.toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination Controls */}
        <div className="flex justify-between items-center mt-4">
          <button
            onClick={handlePrev}
            disabled={currentPage === 1}
            className={`px-4 py-2 text-sm font-medium ${
              currentPage === 1
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-blue-600 text-white hover:bg-blue-700"
            } rounded-lg`}
          >
            Previous
          </button>
          <span className="text-sm font-medium">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={handleNext}
            disabled={currentPage === totalPages}
            className={`px-4 py-2 text-sm font-medium ${
              currentPage === totalPages
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-blue-600 text-white hover:bg-blue-700"
            } rounded-lg`}
          >
            Next
          </button>
        </div>
      </div>

      {isModalOpen && (
        <dialog
          id="my_modal_1"
          className="modal w-full md:w-[500px] align-center"
          open
        >
          <div className="modal-box flex justify-center flex-col items-center w-full">
            <div className="flex justify-between w-full px-10 py-5">
              <h3 className="font-bold text-lg">User Details!</h3>
              <button className="btn" onClick={() => setIsModalOpen(false)}>
                Close
              </button>
            </div>

            {isLoading ? (
              <div className="flex flex-col justify-center items-center w-full h-48">
                <div className="loader mb-4"></div>
                <p className="text-blue-500 text-sm">Please Wait...</p>
              </div>
            ) : (
              <div className="modal-action w-full px-10">
                <form method="dialog">
                  {/* Referral Code */}
                  <div className="mb-4">
                    <div className="flex items-center space-x-2">
                      <input
                        placeholder="Enter Referral Code"
                        type="text"
                        id="referredBy"
                        name="referredBy"
                        className={`w-full p-2 border ${
                          errors.referredBy
                            ? "border-red-500"
                            : "border-gray-300"
                        } rounded-lg focus:ring focus:ring-blue-200 ${
                          isReferralVerified
                            ? "bg-gray-100 cursor-not-allowed"
                            : ""
                        }`}
                        onChange={handleChange}
                        value={userDetails.referredBy}
                        disabled={isReferralVerified}
                      />
                      <button
                        type="button"
                        onClick={handleVerifyReferral}
                        className={`px-4 py-2 text-sm font-medium text-white rounded-lg ${
                          isReferralVerified
                            ? "bg-gray-300 cursor-not-allowed"
                            : "bg-green-600 hover:bg-green-700"
                        }`}
                        disabled={isReferralVerified}
                      >
                        {isReferralVerified ? "Verified" : "Verify"}
                      </button>
                    </div>
                    {errors.referredBy && (
                      <p className="text-red-500 text-sm">
                        {errors.referredBy}
                      </p>
                    )}
                    {isReferralVerified && referralDetails && (
                      <div className="mt-3">
                        <p className="text-sm text-gray-700">
                          <strong>Email:</strong> {referralDetails?.user?.email}
                      
                          <strong>Name:</strong> {referralDetails?.user?.name}
                        </p>
                      </div>
                    )}
                  </div>

                  {/* Name */}
                  <div className="mb-4">
                    <input
                      placeholder="Enter Name"
                      type="text"
                      id="name"
                      name="name"
                      className={`w-full p-2 border ${
                        errors.name ? "border-red-500" : "border-gray-300"
                      } rounded-lg focus:ring focus:ring-blue-200`}
                      onChange={handleChange}
                    />
                    {errors.name && (
                      <p className="text-red-500 text-sm">{errors.name}</p>
                    )}
                  </div>

                  {/* Mobile */}
                  <div className="mb-4">
                    <input
                      placeholder="Enter Mobile"
                      type="text"
                      id="mobile"
                      name="mobile"
                      className={`w-full p-2 border ${
                        errors.mobile ? "border-red-500" : "border-gray-300"
                      } rounded-lg focus:ring focus:ring-blue-200`}
                      onChange={handleChange}
                      value={userDetails.mobile}
                    />
                    {errors.mobile && (
                      <p className="text-red-500 text-sm">{errors.mobile}</p>
                    )}
                  </div>

                  {/* Email */}
                  <div className="mb-4">
                    <input
                      placeholder="Enter Email"
                      type="email"
                      id="email"
                      name="email"
                      className={`w-full p-2 border ${
                        errors.email ? "border-red-500" : "border-gray-300"
                      } rounded-lg focus:ring focus:ring-blue-200`}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <p className="text-red-500 text-sm">{errors.email}</p>
                    )}
                  </div>

                  {/* Password */}
                  <div className="mb-4">
                    <input
                      placeholder="Enter Password"
                      type="text"
                      id="password"
                      name="password"
                      className={`w-full p-2 border ${
                        errors.password ? "border-red-500" : "border-gray-300"
                      } rounded-lg focus:ring focus:ring-blue-200`}
                      onChange={handleChange}
                    />
                    {errors.password && (
                      <p className="text-red-500 text-sm">{errors.password}</p>
                    )}
                  </div>

                  {/* Unit Selection */}
                  <div className="mb-4">
                    <select
                      name="unit"
                      id="unit"
                      value={userDetails.unit}
                      onChange={handleUnitChange}
                      className={`w-full p-2 border ${
                        errors.unit ? "border-red-500" : "border-gray-300"
                      } rounded-lg focus:ring focus:ring-blue-200`}
                    >
                      <option value="">Select Unit</option>
                      {[...Array(15)].map((_, index) => (
                        <option key={index + 1} value={index + 1}>
                          Unit {index + 1}
                        </option>
                      ))}
                    </select>
                    {errors.unit && (
                      <p className="text-red-500 text-sm">{errors.unit}</p>
                    )}
                  </div>

                  {/* Joining Amount */}
                  <div className="mb-4">
                    <input
                      placeholder="Joining Amount"
                      type="text"
                      id="joiningAmount"
                      name="joiningAmount"
                      className="w-full p-2 border border-gray-300 rounded-lg bg-gray-100 cursor-not-allowed"
                      value={userDetails.joiningAmount}
                      disabled
                    />
                  </div>

                  <div className="mb-4">
                    <input
                      placeholder="Enter Transaction ID (Optional)"
                      type="text"
                      id="transactionId"
                      name="transactionId"
                      className={`w-full p-2 border ${
                        errors.transactionId
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded-lg focus:ring focus:ring-blue-200`}
                      onChange={handleChange}
                    />
                    {errors.transactionId && (
                      <p className="text-red-500 text-sm">
                        {errors.transactionId}
                      </p>
                    )}
                  </div>

                  {/* Submit Button */}
                  <div className="flex justify-center w-full pb-5">
                    <button
                      onClick={onSubmit}
                      type="submit"
                      className={`text-white w-full font-medium rounded-lg text-sm px-5 py-2.5 ${
                        isReferralVerified
                          ? "bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
                          : "bg-gray-300 cursor-not-allowed"
                      }`}
                      disabled={!isReferralVerified}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </dialog>
      )}
    </div>
  );
};

RegisterNewUser.propTypes = {
  //   second: PropTypes.third,
  adminActions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  adminActionsState: state.adminActionsState,
});

export default connect(mapStateToProps, { adminActions })(RegisterNewUser);
