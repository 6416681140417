import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import bannerImage from "../../../assets/images/banner1.png";
import bannerVedio from "../../../assets/images/bannerVideo.mp4";
import { NavLink } from "react-router-dom";
import RewardMarquee from "../pages/marqueeCard";

function Home() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Array of carousel images
  const carouselImages = Array.from({ length: 24 }, (_, i) =>
    require(`../../../assets/images/homes/home${String(i + 1).padStart(
      2,
      "0"
    )}.jpg`)
  );
 // The empty dependency array ensures this runs only once when the component mounts


  useEffect(() => {
    localStorage.clear();
    AOS.init({
      duration: 1500,
      delay: 1000,
    });

    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === carouselImages.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, [carouselImages.length]);

  return (
    <>
      {/* Background Video */}
      <div className="fixed top-0 left-0 w-full h-full z-[-10]">
        <video
          className="h-full w-full object-cover"
          src={bannerVedio}
          muted
          autoPlay
          preload="auto"
          loop
        ></video>
        {/* White Overlay */}
        <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-70"></div>
      </div>

      {/* Scrollable Content */}
      <div className="relative z-10">
        <div
          id="service-home"
          className="px-6 py-12 min-h-screen flex items-center"
        >
          {/* Content Section */}
          <div className="relative flex flex-col md:flex-row items-center justify-between w-full">
            {/* Text Section */}
            <div className="flex flex-col items-center justify-center text-center md:w-1/2">
              <h1 className="text-5xl md:text-[4rem] font-bold text-black mt-12 md:mt-0">
                Luxury Living with BRfarms
              </h1>
              <p className="text-xl text-gray-700 mt-4 max-w-2xl">
                Discover luxury living at its finest with BRfarms. We design and
                construct bespoke homes tailored to your dreams. Save money
                while investing in our unique plans to own a premium house for
                your family.
              </p>
              <NavLink
                to={"/signup"}
                className="mt-6 bg-golden text-white py-3 px-8 rounded-full hover:bg-white hover:text-golden shadow-md"
              >
                Get Started
              </NavLink>
            </div>


            {/* Image Section */}
            <div className="flex justify-center items-center md:w-1/2 h-full">
              <img
                src={bannerImage}
                alt="Luxury House"
                className="max-w-full max-h-[80%] bottom-0"
              />
            </div>
          </div>
        </div>
          <RewardMarquee />

        {/* Carousel Section */}
        <div className="container mx-auto text-center px-6 py-12">
          <h2 className="text-4xl font-bold text-black mb-6">
            Invest in Your Dream Home
          </h2>
          <p className="text-xl text-gray-700 mb-6">
            At BRfarms, we provide innovative investment plans to make luxury
            housing accessible for everyone. Save and grow your wealth while we
            construct your perfect home.
          </p>

          <div className="w-full h-screen flex flex-col justify-center items-center relative bg-black">
            {/* Carousel Image */}
            <div className="w-full h-full flex justify-center items-center overflow-hidden">
              <img
                src={carouselImages[currentImageIndex]}
                alt={`Featured Property ${currentImageIndex + 1}`}
                className="object-cover w-full h-full"
              />
            </div>

            {/* Dots Indicator */}
            <div className="absolute bottom-8 flex justify-center space-x-3">
              {carouselImages.map((_, index) => (
                <div
                  key={index}
                  className={`w-4 h-4 rounded-full ${
                    index === currentImageIndex ? "bg-golden" : "bg-gray-300"
                  }`}
                  style={{
                    transition: "all 0.3s ease",
                  }}
                ></div>
              ))}
            </div>
          </div>

          <div className="flex justify-center mt-6">
            <NavLink
              to={"/signup"}
              className="bg-golden border border-white text-white py-3 px-8 rounded-full hover:bg-white hover:text-golden"
            >
              Get Started
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
