// Package Imports
import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { RiTeamLine } from "react-icons/ri";

import { PiSpeakerLowBold } from "react-icons/pi";

import { MdOutlineDashboardCustomize } from "react-icons/md";
import { MdOutlineBusiness } from "react-icons/md";
import { TiBusinessCard } from "react-icons/ti";
import { MdHistory } from "react-icons/md";
import { FaAngleDown } from "react-icons/fa";
import { removeStorageValue } from "../../services/Miscellaneous/LocalStorageServices";
import { getStorageValue } from "../../services/Miscellaneous/LocalStorageServices";

// View Imports

const Sidebar = ({ setIsLogin, open, setOpen }) => {
  const navigate = useNavigate();
  const [userDetail, setUserDetail] = useState({ userName: "", email: "" });
  const dispatch = useDispatch();
  const [showOptions, setShowOptions] = useState(false);
  const [showOptions2, setShowOptions2] = useState(false);

  const [transactionReport, setTransactionReport] = useState(false);
  const location = useLocation();

  // Access the pathname from location
  var currentPathname = location.pathname;

 

  // useEffect(() => {
  //   currentPathname=
  // }, [])

  const logoutHanlder = () => {
    removeStorageValue("token");
    setIsLogin(false);
    navigate("/");
  };
  useEffect(() => {
    const storedUser = getStorageValue("userdetail"); // Fetch `result` from localStorage
    if (storedUser) {
      setUserDetail({
        userName: storedUser.userName || "User",
        email: storedUser.email || "No email available",
      });
    }
  }, []);
  const toggel = () => {
    // alert('sjsj')
    // document.body.classNameList.add('overflow-hidden');
  };
  // document.body.classNameList.add('your-css-className');

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div>
      <nav className="fixed top-0 z-50 w-full bg-userblue80 ">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start">
              <button
                onClick={() => {
                  setOpen(!open);
                }}
                data-drawer-target="logo-sidebar"
                data-drawer-toggle="logo-sidebar"
                aria-controls="logo-sidebar"
                type="button"
                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              >
                <span className="sr-only">Open sidebar</span>
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                  ></path>
                </svg>
              </button>
              <a href="#" className="flex ml-2 md:mr-24">
                
                <span className="self-center text-3xl font-normal font-blackOps  whitespace-nowrap text-white">
                    INVESTMENT
                </span>
              </a>
            </div>
            <div className="flex items-center">
              <div className="flex items-center ml-3 text-sm">
                <div>
                  <button
                    type="button"
                    className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                    aria-expanded={isDropdownOpen}
                    data-dropdown-toggle="dropdown-user"
                    onClick={toggleDropdown}
                  >
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="w-8 h-8 rounded-full"
                      src="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
                      alt="user photo"
                    />
                  </button>
                </div>
                <div
                  className={`z-50 ${
                    isDropdownOpen ? "absolute right-0  w-40 top-10" : "hidden"
                  } my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow`}
                  id="dropdown-user"
                >
                  <div className="px-4 py-3" role="none">
                    <p
                      className="text-sm text-gray-900 dark:text-white"
                      role="none"
                    >
                     {userDetail?.userName} 
                    </p>
                    <p
                      className="text-sm font-medium text-gray-900 truncate dark:text-gray-300"
                      role="none"
                    >
                     {userDetail?.email} 
                    </p>
                  </div>
                  <ul className="py-1" role="none">
                    <li>
                      <a
                        href="/user"
                        className="block px-4 py-2 text-sm text-black font-medium"
                        role="menuitem"
                      >
                        Dashboard
                      </a>
                    </li>
                    <li>
                      <a
                        href="/user/profile"
                        className="block px-4 py-2 text-sm text-black font-medium"
                        role="menuitem"
                      >
                        Settings
                      </a>
                    </li>
                    <li>
                      <a
                        href="/user/TotalIncome"
                        className="block px-4 py-2 text-sm text-black font-medium"
                        role="menuitem"
                      >
                        Earnings
                      </a>
                    </li>
                    <li
                      onClick={() => {
                        logoutHanlder();
                      }}
                    >
                      <p
                        className="block cursor-pointer px-4 py-2 text-sm text-black font-medium"
                        role="menuitem"
                      >
                        Sign out
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {
        <aside
          id="logo-sidebar"
          className={`fixed top-0 left-0 z-40 ${
            open ? "w-64" : "w-0 overflow-hidden"
          } h-screen  duration-300 `}
          aria-label="Sidebar"
        >
          <div
            className={`h-full px-3   pb-4 pt-24 overflow-y-auto userBlueBg duration-300"`}
          >
            <ul className="space-y-5 font-medium text-white">
              <li>
                <a
                  href="/user"
                  className={`flex items-center p-2 ${
                    currentPathname == "/user"
                      ? "bg-userGradiant text-white rounded-md"
                      : ""
                  } `}
                >
                  <MdOutlineDashboardCustomize />

                  <span className="ml-3 text-sm">Dashboard</span>
                </a>
              </li>
              <li>
                <button
                  onClick={() => setShowOptions(!showOptions)}
                  type="button"
                  className={`flex items-center p-2 ${
                    currentPathname == ("/user/Referrals" || "/user/TeamList" || "/user/memberTree")
                      ? "bg-userGradiant text-white rounded-md"
                      : ""
                  } `}
                >
                  <RiTeamLine />
                  <span className="flex-1 ms-3 text-left rtl:text-right text-sm whitespace-nowrap">
                    Team
                  </span>
                  <FaAngleDown className="ml-28 w-4 h-4" />
                </button>
                <ul className={`${showOptions ? "" : "hidden"} py-2 space-y-2`}>
                  <li>
                    <a
                      href="/user/Referrals"
                      className="flex items-center pl-11 text-sm whitespace-nowrap "
                    >
                      Referrals
                    </a>
                  </li>
                  <li>
                    <a
                      href="/user/TeamList"
                      className="flex items-center pl-11 text-sm whitespace-nowrap"
                    >
                      Team List
                    </a>
                  </li>

                  <li>
                    <a
                      href="/user/memberTree"
                      className="flex items-center pl-11 text-sm whitespace-nowrap"
                    >
                      Member Tree
                    </a>
                  </li>
                </ul>
              </li>

              <li>
                <a
                  href="/user/MyBussiness"
                  className={`flex items-center p-2 ${
                    currentPathname == "/user/MyBussiness"
                      ? "bg-userGradiant text-white rounded-md"
                      : ""
                  } `}
                >
                  <MdOutlineBusiness />
                  <span className="ml-3 text-sm">My Business</span>
                </a>
              </li>
              {/* <li>
              <a
                href="/admin/BussinessReport"
                className={`flex items-center p-2 ${
                  currentPathname == "/admin/BussinessReport"
                    ? "bg-userGradiant text-white rounded-md"
                    : ""
                } `}
              >
                <TiBusinessCard />
                <span className="ml-3 text-sm">My Team Business</span>
              </a>
            </li> */}

              <li>
                <a
                  href="/user/Rewards"
                  className={`flex items-center p-2 ${
                    currentPathname == "/user/Rewards"
                      ? "bg-userGradiant text-white rounded-md"
                      : ""
                  } `}
                >
                  <PiSpeakerLowBold />
                  <span className="ml-3 text-sm">Rewards</span>
                </a>
              </li>

              {/* <li>
              <a
                href="/user/Transaction"
                className={`flex items-center p-2 ${
                  currentPathname == "/user/Transaction"
                    ? "bg-userGradiant text-white rounded-md"
                    : ""
                } `}
              >
                <MdHistory />
                <span className="ml-3 text-sm">Transaction History</span>
              </a>
            </li> */}

              <li>
                <button
                  onClick={() => setShowOptions2(!showOptions2)}
                  type="button"
                  className={`flex items-center p-2 ${
                    currentPathname ==
                    ("/user/TotalIncome" || "/user/DirectIncome" || "/user/LevelIncome")
                      ? "bg-userGradiant text-white rounded-md"
                      : ""
                  } `}
                >
                  <RiTeamLine />
                  <span className="flex-1 ms-3 text-left rtl:text-right text-sm whitespace-nowrap">
                    My Income
                  </span>
                  <FaAngleDown className="ml-28 w-4 h-4" />
                </button>
                <ul
                  className={`${showOptions2 ? "" : "hidden"} py-2 space-y-2`}
                >
                  <li>
                    <a
                      href="/user/TotalIncome"
                      className="flex items-center pl-11 text-sm whitespace-nowrap "
                    >
                      Total Income
                    </a>
                  </li>
                  <li>
                    <a
                      href="/user/DirectIncome"
                      className="flex items-center pl-11 text-sm whitespace-nowrap"
                    >
                     Direct Income
                    </a>
                  </li>

                  <li>
                    <a
                      href="/user/LevelIncome"
                      className="flex items-center pl-11 text-sm whitespace-nowrap"
                    >
                      Level Income
                    </a>
                  </li>
                </ul>
              </li>

              <li>
                <a
                  href="/user/Transaction"
                  className={`flex items-center p-2 ${
                    currentPathname == "/user/Transaction"
                      ? "bg-userGradiant text-white rounded-md"
                      : ""
                  } `}
                >
                  <MdHistory />
                  <span className="ml-3 text-sm">Transaction</span>
                </a>
              </li>

              <li>
                <a
                  href="/user/RefferAndEarn"
                  className={`flex items-center p-2 ${
                    currentPathname == "/user/RefferAndEarn"
                      ? "bg-userGradiant text-white rounded-md"
                      : ""
                  } `}
                >
                  <MdHistory />
                  <span className="ml-3 text-sm">Reffer And Earn</span>
                </a>
              </li>

              <li>
                <a
                  href="/user/ChnagePassword"
                  className={`flex items-center p-2 ${
                    currentPathname == "/user/ChnagePassword"
                      ? "bg-userGradiant text-white rounded-md"
                      : ""
                  } `}
                >
                  <MdHistory />
                  <span className="ml-3 text-sm">Change Passcode</span>
                </a>
              </li>


              
            </ul>
          </div>
        </aside>
      }
    </div>
  );
};

export default Sidebar;
