import { errorHandler } from "../../../../services/Miscellaneous/ErrorHandlerServices";
import { userListServices, activationRequestServices } from "../../../../services/admin/AdminServices";
import { USER_LIST, ACTIVATION_REQUEST_LIST } from "../../../../utils/Types";

// Action to fetch the user list
export const adminActions = () => async (dispatch) => {
  try {
    const response = await userListServices(); // Fetch data from the service
    dispatch({ type: USER_LIST, payload: response?.result }); // Dispatch the user list data
  } catch (err) {
    errorHandler(err);
  }
};

// Action to fetch the activation request list
export const adminActivationRequestAction = () => async (dispatch) => {
  try {
    const response = await activationRequestServices();
    
    dispatch({
      type: ACTIVATION_REQUEST_LIST,
      payload: response.result || [], // Use only the `result` array
    });
  } catch (error) {
    console.error("Error in adminActivationRequestAction:", error);
    errorHandler(error); // Handle error appropriately
  }
};

