import React from "react";
import Home from "./components/Index";
import AboutUs from "./pages/About";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import Navbar from "./components/Navbar";
import RewardMarquee from "./pages/marqueeCard";

function PublicView() {
  
  return (
    <div className="overflow-hidden  bg-white bg-opacity-40">
      <Navbar />
      <Home />
   
      <AboutUs />
      <Services />
      <Contact />

    </div>
  );
}

export default PublicView;
