import React, { useEffect, useState } from "react";
import { errorHandler } from "../../../services/Miscellaneous/ErrorHandlerServices";
import { mytranssactionListServices } from "../../../services/user/UserServices";
import { getDateAndTimeByTimestamp } from "../../../utils/DateAndTimeFunctions.js";

function Transaction() {
  const [totalIncomeList, setTotalIncomeList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;

  const refferralsList = async () => {
    try {
      let response = await mytranssactionListServices();
      setTotalIncomeList(response.result);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    refferralsList();
  }, []);

  // Calculate the items for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = totalIncomeList.slice(startIndex, endIndex);

  // Handle pagination
  const totalPages = Math.ceil(totalIncomeList.length / itemsPerPage);

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="h-screen overflow-hidden">
      <div className="grid lg:grid-cols-1 grid-cols-1 px-4 py-5 m-4 bg-gradient-to-l from-transparent to-blue-900 rounded-2xl border-[0.5px] border-white/10 shadow-xl">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-white uppercase bg-userGradiant ">
              <tr>
                <th scope="col" className="p-4">Sl</th>
                <th scope="col" className="px-6 py-3">Date & time</th>
                <th scope="col" className="px-6 py-3">Narration</th>
                <th scope="col" className="px-6 py-3">Currency</th>
                <th scope="col" className="px-6 py-3">Amount</th>
                <th scope="col" className="px-6 py-3">Transaction ID</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((ele, ind) => (
                <tr key={ind} className="border-b hover:bg-blue-900">
                  <td className="w-4 p-4">{startIndex + ind + 1}</td>
                  <th className="px-6 py-4 text-white">
                    {getDateAndTimeByTimestamp(ele?.transactionDate)}
                  </th>
                  <td className="px-6 py-4 font-medium text-white whitespace-nowrap dark:text-white">
                    {ele?.narration}
                  </td>
                  <td className="px-6 py-4 text-white">
                    {ele?.currency === "1" && "₹ INR"}
                    {ele?.currency === "2" && "$ USD"}
                  </td>
                  <td className="px-6 py-4 text-white">{ele?.amount}</td>
                  <td className="px-6 py-4 text-white">{ele?.transactionId}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* Pagination Controls */}
          <div className="flex justify-between items-center mt-4 px-4">
            <button
              onClick={handlePrevious}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-lg ${currentPage === 1 ? "bg-gray-500 cursor-not-allowed" : "bg-blue-600"}`}
            >
              Previous
            </button>
            <span className="text-white">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNext}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-lg ${currentPage === totalPages ? "bg-gray-500 cursor-not-allowed" : "bg-blue-600"}`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Transaction;