import React, { useEffect, useState } from "react";
import { refferralsListServices } from "../../../services/user/UserServices";
import { errorHandler } from "../../../services/Miscellaneous/ErrorHandlerServices";
import moment from "moment";

function Referrals() {
  const [listData, setListData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;

  const refferralsList = async () => {
    try {
      let response = await refferralsListServices();
      setListData(response.result);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    refferralsList();
  }, []);

  // Pagination logic
  const totalPages = Math.ceil(listData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = listData.slice(startIndex, startIndex + itemsPerPage);

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="h-screen scrollable-content">
      <div className="grid lg:grid-cols-1 grid-cols-1 px-4 py-5 m-4 bg-gradient-to-l from-transparent to-blue-900 rounded-2xl border-[0.5px] border-white/10 shadow-xl">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <div className="pb-4 dark:bg-gray-900">
            <label htmlFor="table-search" className="sr-only">
              Search
            </label>
            <div className="relative mt-1">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="text"
                id="table-search"
                className="block pt-2 ps-10 text-sm text-gray-900 border border-userGradiant bg-userGradiant rounded-md"
                placeholder="Search for items"
              />
            </div>
          </div>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 overflow-auto">
            <thead className="text-xs text-white uppercase bg-userGradiant">
              <tr>
                <th scope="col" className="p-4">
                  Sl
                </th>
                <th scope="col" className="px-6 py-3">
                  Member Id
                </th>
                <th scope="col" className="px-6 py-3">
                  Referred By
                </th>
                <th scope="col" className="px-6 py-3">
                  Parent Id
                </th>
                <th scope="col" className="px-6 py-3">
                  Email
                </th>
                <th scope="col" className="px-6 py-3">
                  Plan
                </th>
                <th scope="col" className="px-6 py-3">
                  Joining Date
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((ele, ind) => (
                <tr key={ind} className="border-b hover:bg-blue-900">
                  <td className="w-4 p-4">{startIndex + ind + 1}</td>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-white whitespace-nowrap dark:text-white"
                  >
                    {ele?.ownId}
                  </th>
                  <td className="px-6 py-4 text-white">{ele?.referredBy}</td>
                  <td className="px-6 py-4 text-white">{ele?.parentId}</td>
                  <td className="px-6 py-4 text-white">{ele?.email}</td>
                  <td className="px-6 py-4 text-white">{ele?.joiningAmount}</td>
                  <td className="px-6 py-4 text-white">
                    <a
                      href="#"
                      className="font-medium text-white dark:text-blue-500 hover:underline"
                    >
                      {moment(ele?.createdAt).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* Pagination Controls */}
          <div className="flex justify-between items-center mt-4 px-4">
            <button
              onClick={handlePrevious}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-lg ${
                currentPage === 1 ? "bg-gray-500 cursor-not-allowed" : "bg-blue-600"
              }`}
            >
              Previous
            </button>
            <span className="text-white">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNext}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-lg ${
                currentPage === totalPages
                  ? "bg-gray-500 cursor-not-allowed"
                  : "bg-blue-600"
              }`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Referrals;