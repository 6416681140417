import React, { useState, useEffect } from "react";

import { createWithdrawalRequestServices, getUserDetailService, getWithdrawalRequestServices } from "../../../services/user/UserServices";
import { getStorageValue } from "../../../services/Miscellaneous/LocalStorageServices";

const UserWithdrawal = () => {
  const [amount, setAmount] = useState("");
  const [withdrawalType, setWithdrawalType] = useState("BANK");
  const [requests, setRequests] = useState([]);
  const [userData, setUserData] = useState([]);

  // Fetch withdrawal requests
  const fetchRequests = async () => {
    const userDetails = await getUserDetailService();// Assuming this function retrieves user data from local storage
    
   setUserData(userDetails.result);

    try {
      console.log(userDetails.result._id)
      let payload=(
        {userId: userDetails.result._id}
      )
      const response = await getWithdrawalRequestServices(payload);
      setRequests(response.data)

  
    } catch (error) {
      console.error("Error fetching withdrawal requests:", error);
    }
  };

  useEffect(() => {
    fetchRequests();
  }, []);

  // Map numeric status to human-readable format
  const getStatusLabel = (status) => {
    switch (status) {
      case 1:
        return { label: "Pending", style: "bg-yellow-100 text-yellow-800" };
      case 2:
        return { label: "Approved", style: "bg-green-100 text-green-800" };
      case 3:
        return { label: "Rejected", style: "bg-red-100 text-red-800" };
      default:
        return { label: "Unknown", style: "bg-gray-100 text-gray-800" };
    }
  };

  const handleSubmit = async () => {
    try {
      // let userDetails = getStorageValue("userBasicDetail")
      const response = await createWithdrawalRequestServices ({
        userId: userData._id, // Replace with dynamic userId
        amount: Number(amount),
        withdrawalType,
      });
      
      alert("Withdrawal request submitted successfully!");
      fetchRequests();
    } catch (error) {
      alert(error.response.data.message || "Error submitting withdrawal request.");
    }
  };
 


  return (
    <div className="flex flex-col items-center p-8 bg-gardient from-bg-blue-100 to-transparent min-h-screen">
    <div className="bg-white shadow-md rounded-lg w-full max-w-md p-6">
      <h1 className="text-2xl font-bold text-gray-800 mb-6 text-center">Raise Withdrawal Request</h1>
      <h4 className="text-2xl font-bold text-gray-800 mb-6 text-center">₹ {userData.walletAmount}</h4>

      <div className="space-y-4">
        <input
          type="number"
          placeholder="Enter Amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-400 focus:outline-none"
        />
        <select
          value={withdrawalType}
          onChange={(e) => setWithdrawalType(e.target.value)}
          className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-400 focus:outline-none"
        >
          <option value="BANK">Bank</option>
          <option value="UPI">UPI</option>
          <option value="CRYPTO">USDT (BEP-20)</option>
        </select>
        <button
          onClick={handleSubmit}
          className="w-full bg-blue-500 text-white p-3 rounded-lg font-medium hover:bg-blue-600 transition-colors"
        >
          Submit
        </button>
      </div>
    </div>
  
    <div className="bg-white shadow-md rounded-lg w-full max-w-3xl p-6 mt-8">
      <h2 className="text-xl font-semibold text-gray-800 mb-4">
        Your Requests
      </h2>
      <ul className="divide-y divide-gray-200">
        {requests.map((request) => {
          const status = getStatusLabel(request.status);
          return (
            <li
              key={request._id}
              className="flex justify-between items-center py-4"
            >
              <div>
                <span className="text-gray-800 font-medium">
                  {request.amount} {request.currency}
                </span>
                <span className="text-gray-500 ml-2">
                  ({request.withdrawalType})
                </span>
              </div>
              <span
                className={`px-3 py-1 text-sm font-medium rounded-lg ${status.style}`}
              >
                {status.label}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  </div>
  
  );
};

export default UserWithdrawal;
