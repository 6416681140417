import { USER_LIST, ACTIVATION_REQUEST_LIST } from "../../utils/Types";

const initialState = {
  userList: [], // Default to an empty array for user list
  activationRequestList: [], // Default to an empty array for activation requests
  loading: false, // Loading state for any async actions
};

export const adminActionsState = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTIVATION_REQUEST_LIST: {
      return { ...state, activationRequestList: payload || [] }; // Ensure payload is an array
    }
    case USER_LIST: {
     // Log for debugging
      return { ...state, userList: payload || [] }; // Ensure payload is an array
    }
    default: {
      return state; // Default case returns the current state
    }
  }
};
