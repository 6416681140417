import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FaFileArchive } from "react-icons/fa";

import { FaFileAlt } from "react-icons/fa";
import { FaMoneyBillWave } from "react-icons/fa";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { FaUsersBetweenLines } from "react-icons/fa6";
import { FaUsersViewfinder } from "react-icons/fa6";
import { GiProfit } from "react-icons/gi";
import { FaThumbsDown } from "react-icons/fa";
import Confetti from "react-confetti";
import UserMoneyBox from "../../../components/common/UserMoneyBox";
import NormalBox from "../../../components/common/normalBox.js";
import AccountDetailsBox from "../../../components/common/accountBox.js";

import { VscLiveShare } from "react-icons/vsc";
import { MdOutlineDateRange } from "react-icons/md";
import { IoWalletOutline } from "react-icons/io5";
import { PiUserFocusThin } from "react-icons/pi";
import { MdOutlineNumbers } from "react-icons/md";
import { errorHandler } from "../../../services/Miscellaneous/ErrorHandlerServices";
import { Pie } from "react-chartjs-2";
import axios from "axios";
import {
  generateOtpForWithdrawServices,
  getUserDetailService,
  refreshRewardList,
  updateProfilePictureServices,
  updateProfileServices,
  withdrawServices,
} from "../../../services/user/UserServices";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import { successTost } from "../../../utils/Helper";
import { useNavigate } from "react-router-dom";
import { IoIosSettings } from "react-icons/io";
import { getDDMMYYYYByTimestamp } from "../../../utils/DateAndTimeFunctions.js";
import { setStorageValue } from "../../../services/Miscellaneous/LocalStorageServices";
import { BASE_FILE_PATH, BASE_URL } from "../../../utils/Urls.js";
import { getRewardlistservice } from "../../../services/auth/AuthServices.js";

export const UserHome = (props) => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const navigate = useNavigate();

  const [dashboardDetails, setDashboardDetails] = useState({});
  const [levelData, setLevelData] = useState(null);
  // const [depositModal, setDepositeModal] = useState(false);
  const [widthDrawModal, setWidthDrawModal] = useState(false);
  // const [upgradeModal, setUpgradeModal] = useState(false);
  // const [editModal, setEditModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [rewardLevel, setRewardLevel] = useState(null);
  const [isppModal, setIsppModalOpen] = useState(false);
  const [step, setStep] = useState(1); // Step 1: Verify User, Step 2: Enter Amount & OTP
  const [receiverId, setReceiverId] = useState("");
  const [isReceiverValid, setIsReceiverValid] = useState(false);
  const [amount, setAmount] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const profileImage =
    `${BASE_FILE_PATH}/files/${dashboardDetails.image}` ||
    "https://cdn.vectorstock.com/i/2000v/51/87/student-avatar-user-profile-icon-vector-47025187.avif";

  useEffect(() => {
    const dashboardData = async () => {
      try {
        let response = await getUserDetailService();
        console.log(response);
        setStorageValue("userBasicDetail", response?.result);
        setDashboardDetails(response.result);
      } catch (error) {
        errorHandler(error);
      }
    };
    dashboardData();
    fetchData();
  }, []);
  // Fetch reward list and check user ID
  const fetchData = async () => {
    try {
      const checkReward = await getRewardlistservice();
      const matchedReward = checkReward.result.data.find(
        (reward) => reward.userId === dashboardDetails._id
      );

      if (matchedReward) {
        setRewardLevel(matchedReward.achievedLevel); // Set achieved level for modal
        setLevelData(matchedReward); // Set matched reward details to levelData
        setShowModal(true); // Show modal
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // API Mocks
  const verifyUserAPI = async (receiverId) => {
    // Replace with real API call
    console.log("Verifying user...");
    return receiverId === "validUser123"; // Mock validation
  };

  const sendOtpAPI = async () => {
    // Replace with real API call
    console.log("Sending OTP...");
    return true; // Mock OTP send success
  };

  const verifyOtpAPI = async (otp) => {
    // Replace with real API call
    console.log("Verifying OTP...");
    return otp === "1234"; // Mock OTP validation
  };

  const transferAmountAPI = async (receiverId, amount) => {
    // Replace with real API call
    console.log("Transferring amount...");
    return true; // Mock transfer success
  };

  const handleVerifyUser = async () => {
    setLoading(true);
    const isValid = await verifyUserAPI(receiverId);
    setLoading(false);
    if (isValid) {
      setIsReceiverValid(true);
      setStep(2);
    } else {
      alert("Invalid User ID");
    }
  };

  const handleSendOtp = async () => {
    setLoading(true);
    const otpSent = await sendOtpAPI();
    setLoading(false);
    if (otpSent) {
      setIsOtpSent(true);
      alert("OTP sent to your registered email!");
    } else {
      alert("Failed to send OTP.");
    }
  };

  const handleVerifyOtpAndTransfer = async () => {
    setLoading(true);
    const isOtpValid = await verifyOtpAPI(otp);
    if (isOtpValid) {
      const isTransferSuccess = await transferAmountAPI(receiverId, amount);
      setLoading(false);
      if (isTransferSuccess) {
        alert("Transfer Successful!");
        setIsppModalOpen(false);
        // Reset all states
        setStep(1);
        setReceiverId("");
        setIsReceiverValid(false);
        setAmount("");
        setOtp("");
        setIsOtpSent(false);
      } else {
        alert("Transfer Failed.");
      }
    } else {
      setLoading(false);
      alert("Invalid OTP.");
    }
  };

  // const autpTopUpHandler = async () => {
  //   setTopStatus(!topStatus);
  //   let payload = {
  //     updateAutoTopupEnabled: !topStatus,
  //   };
  //   try {
  //     let response = await updateAutoTopServices(payload);
  //     // successTost()
  //   } catch (error) {
  //     errorHandler(error);
  //   }
  // };

  const maximum = dashboardDetails.joiningAmount * 3 ?? 0;
  const achievement = dashboardDetails.totalIncome ?? 0;

  const pieData = {
    labels: ["Achieved", "Remaining"],
    datasets: [
      {
        label: "Income Chart 3x",
        data: [achievement, Math.max(maximum - achievement, 0)], // Ensure no negative values
        backgroundColor: ["#AFDC8F", "#92C5F9"], // Colors for Achieved and Remaining
        // hoverBackgroundColor: ["#00FFCC", "#BBBBBB"],
        borderWidth: 0,
      },
    ],
  };

  return (
    <div className="h-screen scrollable-content relative">
      <div className="grid lg:grid-cols-4 grid-cols-2 text-white">
        <button
          onClick={() => {
            navigate("/user/RefferAndEarn");
          }}
        >
          <NormalBox name="Refer & Earn" icon={<VscLiveShare size={32} />} />
        </button>
        <NormalBox
          name="Date of Joining"
          textData={getDDMMYYYYByTimestamp(dashboardDetails?.activateDate)}
          icon={<MdOutlineDateRange size={32} />}
        />
        <NormalBox
          name="CustomerID"
          textData={dashboardDetails?.ownId}
          icon={<PiUserFocusThin size={32} />}
        />
        <NormalBox
          name="Units Buy"
          textData={dashboardDetails?.joiningAmount / 15000}
        />
      </div>
      <h1> Income Received Till {Date.now()}</h1>
      <div className="grid lg:grid-cols-3 grid-cols-1 text-white">
        <UserMoneyBox
          name="Total Income"
          // percent={0}
          money={dashboardDetails?.totalIncome}
          icon={<FaFileAlt size={32} />}
        />

        <UserMoneyBox
          name="Direct Income"
          percent={0}
          money={dashboardDetails?.directIncome}
          icon={<GiProfit size={32} />}
        />
        <UserMoneyBox
          name="Reward Income"
          percent={0}
          money={dashboardDetails?.rewardIncome}
          icon={<GiProfit size={32} />}
        />
      </div>
      <div className="grid lg:grid-cols-4 grid-cols-2 text-white">
        <UserMoneyBox
          name="Promotional Bonus"
          money={dashboardDetails?.promotionalBonus}
          icon={<PiUserFocusThin size={32} />}
        />
        <UserMoneyBox
          name="ROI"
          money={dashboardDetails?.roiIncome}
          icon={<PiUserFocusThin size={32} />}
        />
        <UserMoneyBox
          name="Growth Bonus"
          money={dashboardDetails?.growthIncome}
          icon={<PiUserFocusThin size={32} />}
        />
        <UserMoneyBox
          name="Lost Income"
          percent={0}
          money={dashboardDetails?.lostIncome}
          icon={<FaThumbsDown size={32} />}
          boxClass="bg-gradient-to-r from-red-500 to-red-700 text-white" // Pass a custom class for red shade
        />
      </div>

      <div className="flex flex-wrap-reverse">
        <div className="flex  items-center px-4 py-5 m-4 bg-gradient-to-l from-transparent to-blue-900 rounded-2xl border-[0.5px] border-white/10 shadow-xl w-[100vw] lg:w-[50%] ">
          <img
            className="h-20 w-20 lg:h-40 lg:w-40 rounded-full"
            src={profileImage}
          />
          <div className="grid grid-cols-1 text-white ml-3">
            <div className="flex items-center">
              <p className="text-gray-300 text-sm">Welcome back,</p>
              <button onClick={() => navigate("/user/profile")}>
                <IoIosSettings className="text-3xl text-white mx-2" />
              </button>
            </div>
            <span className="text-white text-3xl font-bold mt-3">
              {dashboardDetails?.name}
            </span>
            <p className="mb-2">{dashboardDetails?.email}</p>
          </div>
        </div>
        <div className="300 bg-blue-600/10 py-3 rounded-lg m-4 w-[95vw] lg:w-[40%]">
          <UserMoneyBox
            name="Wallet"
            percent={0}
            money={
              dashboardDetails?.walletAmount !== undefined
                ? dashboardDetails.walletAmount.toFixed(2)
                : "0.00"
            }
            icon={<IoWalletOutline color="white" size={32} />}
          />

          <div className="p-5 flex">
            {/* <button
              onClick={() => setDepositeModal(true)}
              className="bg-blue-600 text-white font-semibold rounded-lg flex items-center text-sm p-3"
            >
              <p>Deposit</p>
            </button> */}
            <button
              onClick={() => navigate("/user/withdrawalrequest")}
              className={`font-semibold rounded-lg flex items-center text-sm p-3 ml-3 ${
                dashboardDetails?.walletAmount > 1000
                  ? "bg-indigo-500 text-white"
                  : "bg-gray-400 text-gray-200 cursor-not-allowed"
              }`}
              disabled={!(dashboardDetails?.walletAmount > 1000)}
            >
              <p>Withdraw Request</p>
            </button>
            <button
              onClick={() => setWidthDrawModal(true)}
              className={`font-semibold rounded-lg flex items-center text-sm p-3 ml-3 ${
                dashboardDetails?.walletAmount > 1000
                  ? "bg-teal-800 text-white"
                  : "bg-gray-400 text-gray-200 cursor-not-allowed"
              }`}
              disabled={!(dashboardDetails?.walletAmount > 1000)}
            >
              <p>Withdraw USDT</p>
            </button>

            <button
              onClick={() => setIsppModalOpen(true)}
              className={`font-semibold rounded-lg flex items-center text-sm p-3 ml-3 ${
                dashboardDetails?.walletAmount > 1000
                  ? "bg-teal-800 text-white"
                  : "bg-gray-400 text-gray-200 cursor-not-allowed"
              }`}
              disabled={!(dashboardDetails?.walletAmount > 1000)}
            >
              <p>P2P Transfer</p>
            </button>
            {/* <button
              onClick={() => setUpgradeModal(true)}
              className="bg-blue-600 text-white font-semibold rounded-lg flex items-center text-sm p-3 ml-3"
            >
              <p>Upgrade plan</p>
            </button> */}
          </div>
        </div>
      </div>

      <div className="grid lg:grid-cols-4 grid-cols-2 text-white"></div>

      <div className="h-screen scrollable-content">
        <div className="grid lg:grid-cols-4 grid-cols-1 text-white">
          <AccountDetailsBox
            accountNumber={dashboardDetails?.accountNumber}
            bankName={dashboardDetails?.bankName}
            ifscCode={dashboardDetails?.ifscCode}
            icon={<MdOutlineNumbers size={32} />}
          />
          <UserMoneyBox
            name="Total Withdraw"
            // percent={-66}
            money={dashboardDetails?.withdarw}
            icon={<FaMoneyBillWave size={32} />}
          />

          <NormalBox
            name="Referral Member"
            // percent={-66}
            textData={dashboardDetails?.totalReferralMembers}
            icon={<FaUsersBetweenLines size={32} />}
          />

          <UserMoneyBox
            name="My Investment"
            // percent={-66}
            money={dashboardDetails?.joiningAmount}
            icon={<FaMoneyBillTrendUp size={32} />}
          />
        </div>
        <div className="grid lg:grid-cols-4 grid-cols-2 text-white">
          <NormalBox
            name="Downline Members"
            // percent={-66}
            textData={dashboardDetails?.downlineMembers}
            icon={<FaUsersViewfinder size={32} />}
          />
        </div>
        <div>
          {/* Modal for congratulations */}
          {showModal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <Confetti width={window.innerWidth} height={window.innerHeight} />
              <div className="bg-white rounded-lg shadow-lg p-6 w-96 text-center">
                <h2 className="text-4xl font-bold text-green-600 mb-4">
                  🎉 Congratulations! 🎉
                </h2>
                <p className="text-lg text-gray-800">
                  You have achieved{" "}
                  <span className="font-bold text-blue-500">
                    Level {rewardLevel}
                  </span>
                  !
                </p>
                <p className="text-md text-gray-600 mt-2">
                  Keep it up to enjoy more benefits in the future.
                </p>
                <button
                  onClick={() => setShowModal(false)}
                  className="mt-6 px-4 py-2 bg-blue-500 text-white rounded shadow hover:bg-blue-600 transition"
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="h-screen">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-white p-4">
            {/* Pie Chart Component */}
            <div className="w-full flex justify-center">
              <div className="w-80 h-80 text-white">
                <Pie
                  data={pieData}
                  options={{
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label: function (tooltipItem) {
                            const dataset = tooltipItem.dataset;
                            const value = dataset.data[tooltipItem.dataIndex];
                            return `${
                              tooltipItem.label
                            }: ₹ ${value.toLocaleString()}`;
                          },
                        },
                      },
                    },
                    animation: {
                      animateScale: true,
                      animateRotate: true,
                    },
                    maintainAspectRatio: false,
                  }}
                />
                <div className="text-center mt-4">
                  <p className="text-xl font-bold">
                    Achievement: ₹ {(achievement || 0).toLocaleString()}
                  </p>
                  <p className="text-lg">
                    Maximum: ₹ {(maximum || 0).toLocaleString()}
                  </p>
                </div>
              </div>
            </div>

            {/* Level Card Component */}
            <div className="w-full flex justify-center">
              <div
                className="m-5 p-10 text-center neon-border bg-gradient-to-r from-transparent to-blue-800 rounded-lg shadow-xl place-self-center w-80"
                onClick={() => navigate("/user/Rewards")}
                style={{ cursor: "pointer" }}
              >
                <h2 className="text-2xl font-bold mb-4">Level in Progress</h2>
                <p className="text-xl">
                  <strong>Level:</strong> {levelData?.nextLevel}
                </p>
                <p className="text-lg">
                  <strong>Units Achieved:</strong> {levelData?.units}
                </p>
                <p className="text-lg">
                  <strong>Children Achieved:</strong> {levelData?.children}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* {depositModal && (
        <DepositeModal
          depositModal={depositModal}
          setDepositeModal={setDepositeModal}
        />
      )} */}
        {widthDrawModal && (
          <WithdrawModal
            widthDrawModal={widthDrawModal}
            setWidthDrawModal={setWidthDrawModal}
          />
        )}
        {/* {upgradeModal && (
        <UpgradePlanModal
          upgradeModal={upgradeModal}
          setUpgradeModal={setUpgradeModal}
        />
      )} */}
      </div>
    </div>
  );
};

UserHome.propTypes = {
  //   second: PropTypes.third
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserHome);

// const DepositeModal = ({ depositModal, setDepositeModal }) => {
//   return (
//     <div className="w-screen h-screen bg-blue-950/60 z-50  right-0 fixed top-0 flex justify-center items-center">
//       <div className="w-full lg:w-[400px] bg-userTheme/80 rounded-lg">
//         <form class="w-[90%] mx-auto py-3">
//           <p className="text-white text-2xl font-blackOps">Deposit</p>
//           <div class="flex items-start  py-4">
//             <input
//               type="number"
//               placeholder="Enter Amount"
//               className="border w-full outline-none"
//             />
//           </div>
//           <div className="flex justify-end ">
//             <button
//               onClick={() => setDepositeModal(false)}
//               type="submit"
//               class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
//             >
//               Cancel
//             </button>

//             <button
//               type="submit"
//               class="text-white ml-3 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
//             >
//               Submit
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

const WithdrawModal = ({ widthDrawModal, setWidthDrawModal }) => {
  const [amount, setAmount] = useState(0);
  const [walletAddress, setWalletAddress] = useState("");
  const [otp, setOtp] = useState("");

  const otpForWithdraw = async () => {
    let payload = {
      amount: amount,
      walletAddress: walletAddress,
    };
    try {
      let response = await generateOtpForWithdrawServices(payload);
      successTost("Otp send sucessfully");
    } catch (error) {
      errorHandler(error);
    }
  };

  const widthDrawHandler = async () => {
    let payload = {
      amount: amount,
      otp: otp,
      walletAddress: walletAddress,
    };
    try {
      await withdrawServices(payload);
      successTost("Withdraw sucessfully");
      setWidthDrawModal(false);
    } catch (error) {
      errorHandler(error);
    }
  };
  return (
    <div className="w-screen h-screen bg-blue-950/60 z-50  right-0 fixed top-0 flex justify-center items-center">
      <div className="w-full lg:w-[400px] bg-userTheme/80 rounded-lg">
        <form class="w-[90%] mx-auto py-3">
          <p className="text-white text-2xl font-blackOps">Withdraw</p>
          <div class="flex items-start  py-4 flex-col gap-y-2">
            <input
              onChange={(e) => setAmount(e.target.value)}
              type="number"
              placeholder="Enter Amount"
              className="border w-full outline-none rounded-lg"
            />
            <input
              onChange={(e) => setWalletAddress(e.target.value)}
              type=""
              placeholder="Enter Wallet Address"
              className="border w-full outline-none rounded-lg py-2"
            />
            <input
              type="number"
              placeholder="Enter Otp"
              className="border w-full outline-none rounded-lg"
            />
            <p
              onClick={otpForWithdraw}
              className="text-theme ml-auto cursor-pointer font-semibold"
            >
              Get OTP
            </p>
          </div>
          <div className="flex justify-end ">
            <button
              onClick={() => setWidthDrawModal(false)}
              type="submit"
              class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Cancel
            </button>

            <button
              onClick={widthDrawHandler}
              type="submit"
              class="text-white ml-3 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

// const UpgradePlanModal = ({ upgradeModal, setUpgradeModal }) => {
//   const [selectedPlan, setSelectedPlan] = useState(null);
//   const upgradePlanHandler = async () => {
//     try {
//     } catch (error) {}
//   };
//   return (
//     <div className="w-screen h-screen bg-blue-950/60 z-50  right-0 fixed top-0 flex justify-center items-center">
//       <div className="w-full lg:w-[400px] bg-userTheme/80 rounded-lg">
//         <form class="w-[90%] mx-auto py-3">
//           <p className="text-white text-2xl font-blackOps">Upgrade Plan</p>
//           <div class="flex items-start  py-4 w-full flex-col">
//             <label
//               for="small"
//               class="block mb-2 text-sm font-medium te-900 text-white"
//             >
//               Select Upgrade Plan
//             </label>
//             <select
//               onChange={(e) => setSelectedPlan(e.target.value)}
//               id="small"
//               class="block w-[100%] p-2 mb-6 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
//             >
//               <option selected>Choose a plan</option>
//               <option value="55"></option>
//               <option value="110">$110</option>
//               <option value="220">$220</option>
//               <option value="440">$440</option>
//               <option value="880">$880</option>
//               <option value="1760">$1760</option>
//               <option value="3520">$3520</option>
//               <option value="7040">$7040</option>
//               <option value="14080">$14080</option>
//             </select>
//           </div>
//           <div className="flex justify-end ">
//             <button
//               onClick={() => setUpgradeModal(false)}
//               type="submit"
//               class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
//             >
//               Cancel
//             </button>

//             <button
//               type="submit"
//               class="text-white ml-3 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
//             >
//               Submit
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };


