import React, { useEffect } from "react";

import backgroundVideo from "../../../assets/images/bannerVideo.mp4"; // Add your video path here
import service1 from "../../../assets/images/service1.png";
import service2 from "../../../assets/images/service2.png";
import service3 from "../../../assets/images/service3.png";
import Gallery from "./gallery";

function Services() {
  const services = [
    {
      heading: "Land Plotting",
      points: [
        "We specialize in transforming raw land into well-planned plots.",
        "Ideal for luxury villa construction.",
        "Plots are strategically located for maximum value and convenience.",
      ],
      img: service1,
    },
    {
      heading: "Luxury Villas Construction",
      points: [
        "Design and construct state-of-the-art luxury villas.",
        "Focus on modern architecture and premium quality materials.",
        "Customized solutions to meet individual client needs.",
      ],
      img: service2,
    },
    {
      heading: "Resale Services",
      points: [
        "Resell developed plots and luxury homes at competitive prices.",
        "Transparent processes and reliable documentation.",
        "Support for both investors and end-users to maximize returns.",
      ],
      img: service3,
    },
  ];

  

  return (
    <div>
    
      {/* Fixed Video Background */}
      <div className="fixed top-0 left-0 w-full h-screen -z-10">
        <video
          autoPlay
          loop
          muted
          className="absolute inset-0 w-full h-full object-cover"
        >
          <source src={backgroundVideo} type="video/mp4" />
        </video>
        {/* Skyblue Blur Overlay */}
        <div className="absolute inset-0 bg-white bg-opacity-20 backdrop-blur-md"></div>
      </div>

      {/* Services Content */}
      <div className="relative container mx-auto px-5 py-20 space-y-20">
        <Gallery />
        <div className="text-center">
          <h1 className="text-5xl md:text-6xl font-bold bg-gradient-to-r from-shuttle to-white text-transparent bg-clip-text">
            Our Services
          </h1>
        </div>

        {services.map((service, index) => (
          <div
            key={index}
            className={`flex flex-col md:flex-row items-center space-y-10 md:space-y-0 ${
              index % 2 !== 0 ? "md:flex-row-reverse" : ""
            }`}
          >
            {/* Service Image */}
            <div className="w-full md:w-1/2 flex justify-center">
              <img
                src={service.img}
                alt={service.heading}
                className="rounded-lg w-full md:w-3/4"
              />
            </div>

            {/* Service Content */}
            <div className="w-full md:w-1/2 space-y-5 p-5 rounded-xl bg-white bg-opacity-50 text-center ">
              <h2 className="text-3xl font-bold ">{service.heading}</h2>
              <ul className="list-none text-2xl text-black space-y-2 ml-5">
                {service.points.map((point, idx) => (
                  <li key={idx} className="text-lg font-thin">
                    {point}
                  </li>
                ))}
              </ul>
              <button className="mt-5 px-6 py-3 bg-golden text-white font-semibold rounded-full hover:bg-white hover:text-golden transition-colors duration-300">
                Buy Home by Invest Now
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Services;
