import React, { useEffect, useState } from "react";


// Utility function to format numbers as K/M/B
const formatNumber = (num) => {
  if (num >= 1_000_000) {
    return `${(num / 1_000_000).toFixed(1)}M`; // Format in Millions
  } else if (num >= 1_000) {
    return `${(num / 1_000).toFixed(1)}K`; // Format in Thousands
  }
  return num.toString(); // Return as-is if below 1K
};

export function DashBoardCard({ heading, value, icon }) {
  // Array of static background colors
  const colorClasses = [
    "bg-blue-600",
    "bg-green-600",
    "bg-gray-700",
    "bg-teal-600",
    "bg-indigo-600",
    "bg-purple-700",
  ];

  // Randomly pick a color for the card
  const color = colorClasses[Math.floor(Math.random() * colorClasses.length)];

  // Counter animation for the value
  const [animatedValue, setAnimatedValue] = useState(0);

  useEffect(() => {
    let startValue = 0;
    const duration = 2000; // Total animation duration in ms
    const startTime = performance.now(); // Get the current timestamp

    const animate = (currentTime) => {
      const elapsedTime = currentTime - startTime; // Calculate elapsed time
      const progress = Math.min(elapsedTime / duration, 1); // Calculate progress (0 to 1)
      const currentValue = Math.floor(progress * value); // Interpolate the current value

      setAnimatedValue(currentValue);

      if (progress < 1) {
        requestAnimationFrame(animate); // Continue animation
      }
    };

    requestAnimationFrame(animate); // Start animation

    return () => {}; // Clean-up if necessary
  }, [value]);

  return (
    <div className="w-full sm:w-1/2 lg:w-1/3 p-4 text-white">
      <div
        className={`${color} rounded-lg border border-gray-300 shadow-lg hover:shadow-xl transition-shadow duration-300 relative h-full overflow-hidden`}
      >
        {/* Icon (Slightly Out of the Box) */}
        <div className="absolute -top-4 -right-4 w-20 h-20 bg-white rounded-full flex items-center justify-center shadow-2xl z-10">
          <i className={`${icon} text-blue-600 text-4xl`}></i>
        </div>

        {/* Card Content */}
        <div className="p-6 relative">
          {/* Heading */}
          <p
            variant="h6"
            color="white"
            className="text-lg font-semibold mb-4 leading-tight"
          >
            {heading}
          </p>

          {/* Value with Animation */}
          <p
            variant="h3"
            color="white"
            className="text-5xl font-extrabold"
          >
            {formatNumber(animatedValue)}
          </p>
        </div>
      </div>
    </div>
  );
}
