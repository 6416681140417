import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../../utils/Urls";

function Rewards() {
  const [nextLevel, setNextLevel] = useState(0); // Tracks the current in-progress level
  const levels = [
    { level: 1, reward: 1000, target: { units: 3, child: 3 } },
    { level: 2, reward: 3000, target: { units: 9, child: 9 } },
    { level: 3, reward: 9000, target: { units: 27, child: 27 } },
    { level: 4, reward: 27000, target: { units: 81, child: 81 } },
    { level: 5, reward: 75000, target: { units: 243, child: 243 } },
    { level: 6, reward: 250000, target: { units: 729, child: 729 } },
    { level: 7, reward: 650000, target: { units: 2187, child: 2187 } },
    { level: 8, reward: 2000000, target: { units: 6561, child: 6561 } },
    { level: 9, reward: 6500000, target: { units: 19683, child: 19683 } },
    { level: 10, reward: 20000000, target: { units: 59049, child: 59049 } },
  ];

  // Fetch reward data
  useEffect(() => {
    const fetchNextLevel = async () => {
      try {
        const response = await axios.post(`${BASE_URL}user/calculate-rewards`);

        console.log("Full Response:", response.data); // Log full response

        // Directly extract nextLevel from response.data (since it's not nested inside `data`)
        const nextLevel = Number(response.data?.nextLevel ?? 0);

        // Log the parsed value and its type
        console.log("Parsed NextLevel:", nextLevel, typeof nextLevel);

        // Set state
        setNextLevel(nextLevel);
      } catch (error) {
        console.error("Error fetching next level:", error);
      }
    };

    fetchNextLevel();
  }, []);

  // Determine status for each level
  const getStatus = (level) => {
    if (level < nextLevel) return "Achieved"; // Levels less than NextLevel are Achieved
    if (level === nextLevel) return "In Progress"; // Current NextLevel is In Progress
    return "Pending"; // Remaining levels are Pending
  };

  return (
    <div className="h-screen overflow-hidden">
      <div className="grid lg:grid-cols-1 grid-cols-1 px-4 py-5 m-4 bg-gradient-to-l from-transparent to-blue-900 rounded-2xl border-[0.5px] border-white/10 shadow-xl">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-white uppercase bg-userGradiant">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Level
                </th>
                <th scope="col" className="px-6 py-3">
                  Reward
                </th>
                <th scope="col" className="px-6 py-3">
                  Target (Units)
                </th>
                <th scope="col" className="px-6 py-3">
                  Target (Child)
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {levels.map((item) => {
                const status = getStatus(item.level);
                return (
                  <tr
                    key={item.level}
                    className={`border-b hover:bg-blue-900 ${
                      status === "In Progress" ? "neon-border" : ""
                    }`}
                  >
                    <td className="px-6 py-4 text-white">{item.level}</td>
                    <td className="px-6 py-4 text-white">
                      ₹ {item.reward.toLocaleString()}
                    </td>
                    <td className="px-6 py-4 text-white">
                      {item.target.units}
                    </td>
                    <td className="px-6 py-4 text-white">
                      {item.target.child}
                    </td>
                    <td
                      className={`px-6 py-4 font-medium ${
                        status === "Achieved"
                          ? "text-green-500"
                          : status === "In Progress"
                          ? "text-yellow-500"
                          : "text-red-500"
                      }`}
                    >
                      {status}
                    </td>
                    <td className="px-6 py-4">
                      {status === "In Progress" && (
                        <button
                          type="button"
                          className="px-3 py-1 text-sm font-medium text-white bg-blue-500 rounded-lg hover:bg-blue-600"
                          onClick={() =>
                            alert(`Working on Level ${item.level}`)
                          }
                        >
                          Work on This
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Rewards;
