import React, { useState } from "react";
import bannerVedio from "../../assets/images/bannerVideo.mp4";
import { Link, useNavigate } from "react-router-dom";
import { loginAction } from "../../redux/action/userAction/authAction/AuthAction";
import { adminLoginServices } from "../../services/auth/AuthServices";
import { errorHandler } from "../../services/Miscellaneous/ErrorHandlerServices";
import { toast } from "react-toastify";
import { setStorageValue } from "../../services/Miscellaneous/LocalStorageServices";
import logo from "../../assets/logo.png";


const AdminLoginView = () => {
  const [loginDetails, setLoginDetails] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginDetails((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const onLogIn = async (e) => {
    e.preventDefault();
    if (!loginDetails.userId) {
      toast.error("Please enter email");
      return;
    }

    if (!loginDetails.password) {
      toast.error("Please enter password");
      return;
    }
    try {
      // Call Service
      let response = await adminLoginServices(loginDetails);
      console.log(response);
      if (response) {
        setStorageValue("token", response?.result.token);
        setStorageValue("userdetails", response?.result)
        navigate("/admin");
      }
    } catch (err) {
      errorHandler(err);
    }
  };

  return (
    <div className="h-screen w-screen  flex ">
      <div className="w-full hidden md:w-2/4 h-screen md:relative md:flex flex-col ">
        <video
          className="h-screen w-full absolute top-0 left-0 object-cover"
          src={bannerVedio}
          controls={false}
          muted
          autoPlay={"autoplay"}
          preload="auto"
          loop
        ></video>
        <div className="absolute flex-col bg-transparent top-0 bg-white bg-opacity-60 flex justify-center items-center h-screen w-full">
        <img
            src={logo}
            className=" mr-3 "
            alt="Loading"
          />
          <p className="text-black  font-semibold mt-2 z-50">
          Luxury Living with BRfarms
          </p>
        </div>
      </div>
      <div className="w-[100%] p-4 md:p-0 md:w-2/4 h-screen md:bg-userTheme flex justify-center items-center flex-col">
        {/* <div className="h-screen w-full  absolute md:hidden top-0 left-0 object-cover "> */}
        <video
          className="h-screen w-full  absolute md:hidden top-0 left-0 object-cover"
          src={bannerVedio}
          controls={false}
          muted
          autoPlay={"autoplay"}
          preload="auto"
          loop
        ></video>
        {/* <div class="overlay-content absolute"></div> */}
        {/* </div> */}
        <div className="w-full md:w-[30rem] mx-auto flex flex-col items-center justify-center">
         
          <p className="text-white text text-3xl font-semibold z-50 font-medium  md:text-[2rem] ">
          Nice to See you Admin
        </p>
        <p className="text-white my-2 mb-4 text-center text-xl z-50">
          Enter your email and password to sign in
        </p>
        </div>
        <div className="w-full md:w-[400px] glassmorphism rounded-md bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10 ">
          <form className="w-full mx-auto bg-transparent p-5  ">
            <div className="mb-5">
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-white dark:text-white"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="userId"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter Your Email"
                required
                onChange={handleChange}
              />
            </div>
            <div className="mb-5">
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-white dark:text-white"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                placeholder="Enter Your Password"
                onChange={handleChange}
              />
            </div>
            <div className="flex items-start mb-5">
              <div className="flex items-center h-5">
                <input
                  id="remember"
                  type="checkbox"
                  value=""
                  className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                />
              </div>
              <label
                htmlFor="remember"
                className="ms-2 text-sm font-medium text-white dark:text-gray-300"
              >
                Remember me
              </label>
            </div>
            <button
              onClick={onLogIn}
              type="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full  px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Login
            </button>
            {/* <p className="text-gray-600 text-center mt-4">
              Don't have an account?{" "}
              <Link to="/admin/register" className="text-white cursor-pointer">
                Register Here{" "}
              </Link>
            </p> */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminLoginView;
