import React, { useState } from "react";
import { errorTost, successTost } from "../../../utils/Helper";
import { errorHandler } from "../../../services/Miscellaneous/ErrorHandlerServices";
import {
  activateUserServices,
  createActiveUser,
} from "../../../services/admin/AdminServices";

const ActivateUser = () => {
  const [formData, setFormData] = useState({
    email: "",
    ownId: "",
    userData: {},
    amount: 0,
  });

  const onChangeHandler = (e) => {
    let { name, value } = e.target;
    setFormData((pre) => ({ ...pre, [name]: value }));
  };

  const { email, ownId, userData, amount } = formData;

  const activeUserHandler = async () => {
    if (!email && !ownId) {
      errorTost("Please enter email or client-Id");
      return;
    }
    try {
      const response = await createActiveUser(formData);
      setFormData((pre) => ({
        ...pre,
        email: "",
        ownId: "",
        userData: response.result,
      }));
      console.log(response);
    } catch (error) {
      errorHandler(error);
    }
  };

  //   console.log(email, "ss");
  const activateUser = async (formData) => {
    let payload = {
      amount: amount,
      email: userData.email,
      transactionId: "NIL",
    };

    try {
      const response = await activateUserServices(payload);
      successTost("User Activate Successfully")
      setFormData((pre) => ({ ...pre, userData: {} }));
      
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <div className="pt-[10px] w-full h-full flex flex-col justify-center p-4">
      <form class=" mx-auto bg-white  rounded-md shadow-md justify-center w-full md:w-[500px] md:px-6 py-5 flex flex-col justify-center ">
        <div className="self-center mb-5">
          <p className="block mb-1 text-lg font-medium text-gray-500 dark:text-white self-center">
            Active User
          </p>
        </div>
        <div class="flex mb-5 items-center">
          <label
            for="email"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white w-[20%]"
          >
            Email
          </label>
          <input
            type="email"
            id="email"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:ring-blue-500 focus:border-blue-500 block w-[80%] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="name@flowbite.com"
            value={email}
            onChange={onChangeHandler}
            required
            name="email"
          />
        </div>
        <div class="flex mb-5 items-center">
          <label
            for="password"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white w-[20%]"
          >
            Client-Id
          </label>
          <input
            type="number"
            id="password"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:ring-blue-500 focus:border-blue-500 block w-[80%] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Client-id"
            value={ownId}
            onChange={onChangeHandler}
            required
            name="ownId"
          />
        </div>
        <button
          onClick={() => activeUserHandler()}
          type="button"
          class="text-white bg-gray-700 w-[200px] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm self-center px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Get Info
        </button>

        {userData.name && (
          <div>
            <div className="flex py-1 mt-5">
              <p className="block text-sm font-medium text-gray-900 dark:text-white w-[30%]">
                Email
              </p>
              <p className="block text-sm font-medium text-gray-900 dark:text-white w-[70%]">
                {userData?.email}
              </p>
            </div>
            <div className="flex py-1">
              <p className="block text-sm font-medium text-gray-900 dark:text-white w-[30%]">
                Referral-ID
              </p>
              <p className="block text-sm font-medium text-gray-900 dark:text-white w-[70%]">
                {userData?.referredBy}
              </p>
            </div>
            <div className="flex py-1">
              <p className="block text-sm font-medium text-gray-900 dark:text-white w-[30%]">
                Stauts
              </p>
              <p className="block text-sm font-medium text-gray-900 dark:text-white w-[70%]">
                {userData?.status}
              </p>
            </div>

            <div className="flex py-1">
              <p className="block text-sm font-medium text-gray-900 dark:text-white w-[30%]">
                Joining Amount
              </p>
              <p className="block text-sm font-medium text-gray-900 dark:text-white w-[70%]">
                {userData?.selectedPlan}
              </p>
            </div>
            <div className="flex items-center py-1">
              <p className="block text-sm font-medium text-gray-900 dark:text-white w-[30%]">
                Enter Amount :
              </p>
              <input
                type="number"
                id="password"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[70%] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="amount"
                value={amount}
                onChange={onChangeHandler}
                required
                name="amount"
              />
            </div>
            <div className="flex justify-center py-1">
              <button
                onClick={() => activateUser()}
                type="button"
                class="text-white bg-gray-700 hover:bg-blue-800 focus:ring-4 mt-5 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                ACTIVATE
              </button>
              <button
                // onClick={() => resetUserHandler()}
                type="button"
                class="text-white bg-gray-700 hover:bg-blue-800 focus:ring-4 ml-5 mt-5 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                CLEAR
              </button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default ActivateUser;
